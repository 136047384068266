<template>
    <v-card
        @drop.prevent="onDrop"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{ 'grey lighten-2': dragover }"
    >
    <v-card-text>
      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-icon onclick="document.getElementById('getFile').click()" class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          mdi-cloud-upload
        </v-icon>
            <input 
                id="getFile" type="file" 
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style="display:none"
                @change="uploadbyclick"
            />
        <p>
            Drop your file(s) here, or click the Cloud to select them.
        </p>
      </v-row>
      <v-virtual-scroll
        v-if="uploadedFiles.length > 0"
        :items="uploadedFiles"
        height="50"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-3 text--secondary">
                  {{ item.size }} bytes</span
                >
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click.stop="removeFile(item.name)" icon>
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
      </v-card-text>
    </v-card>
</template>

<script>
import { snackbar } from "@components/mixins/snackbar";
import { helper } from "@components/mixins/helper";
import * as apiAlertConfigTickCheck from "@components/api/pk/alert-config-tick-check";
// import XLSX from 'xlsx';

export default {
    mixins: [helper, snackbar],
    props: {
        selectedBroker: String,
        callback: Function,
    },
    data() {
        return {
            dragover: false,
            uploadedFiles: [], 
            csvUploading: false,
            filetypesAcceptable: ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
        };
    },

    methods: {
        removeFile(fileName) {
            const index = this.uploadedFiles.findIndex(
                file => file.name === fileName
            );
        // If file is in uploaded files remove it
            if (index > -1) this.uploadedFiles.splice(index, 1);
        },
        onDrop(e) {
            this.dragover = false;
            // Check if there are already uploaded files, replace
            if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
            if (e.dataTransfer.files.length > 1) {
                this.snackBarDanger('Only one file can be uploaded at a time..')
            } else {
                e.dataTransfer.files.forEach(element => {
                    this.uploadedFiles.push(element);
                    console.log(element.type);
                    if (!this.checkFile(element.type)) {
                        this.snackBarDanger('Invalid file type !');
                        this.uploadedFiles = [];
                        return;
                    }
                    this.readFile(element)
                });
            }
        },
        uploadbyclick (e) { 
            e.target.files.forEach(element => {
                this.uploadedFiles.push(element);
                console.log(element.type);
                this.readFile(element)
            });
        },
        readFile (file) {
            let reader = new FileReader();
            reader.onload = (event) => {
                // XLSX.read(data, { type: 'binary'})

                console.log(event.target.result)
                if (this.checkData(event.target.result)) {
                    this.callback().load();
                    this.submitFile();
                } else {
                    this.uploadedFiles = []
                }
            }
            reader.readAsText(file);
        },
        checkFile (filetype) {
            console.log(this.filetypesAcceptable.includes(filetype))
            return this.filetypesAcceptable.includes(filetype)
        },
        checkData (data) {
            const headerchecking = ['symbol','active','send_email','email_gap','th','server'];
            let dataTypechecking = ['active', 'send_email', 'email_gap', 'th', 'server'];
            let chekcingPostion = {}

                        
            // split the string into array
            let lines = data.split('\n');

            // remove the '\r' in the string it may occur after modifyed the file via Excel
            for (let i = 0; i < lines.length; i++) {
                lines[i] = lines[i].replace('\r', '');
            }
                
            //  check data file is empty
            if (lines.length === 1) {
                this.snackBarDanger('No data detected in uploaded file')
                return;
            } 

            // get all columns
            let columns = lines[0].split(','); 
            console.log(columns)
                
                // check header
            if (!(this.compareArray(columns, headerchecking))) {
                this.snackBarDanger(`column name doest not match`)
                this.csvUploading = false;
                return;
            } else {
                // check the position of each column in the header
                // user may mess up the seqeuence of columns
                for (let i = 0; i < columns.length; i++) {
                    chekcingPostion[dataTypechecking[i]] = columns.indexOf(dataTypechecking[i]);
                }	
                    console.log(chekcingPostion);
                    console.log(lines, lines.length)

                for (let i = 1; i < lines.length; i++) {
                    console.log(lines[i])
                    // skip the last starting new line
                    if (lines[i] === '') {
                        continue;
                    }
                    // covert string to array, if last column is list/int push them into array
                    let tuple = lines[i].split(',');
                    console.log(tuple.length, headerchecking.length)
                    if (tuple.length > headerchecking.length) {
                        tuple.splice(headerchecking.length-1, tuple.length)
                        let markStart = lines[i].indexOf('"');
                        let markEnd = lines[i].lastIndexOf('"');      
                        let server = lines[i].slice(markStart+1, markEnd) 
                        tuple.push(server)
                    }
                    console.log(tuple)

                    if (tuple.includes('')) {
                        this.snackBarDanger(`Data is empty at line ${i+1}`)
                        this.csvUploading = false;
                        return
                    } else if (parseInt(tuple[chekcingPostion['active']]) !== 0 && parseInt(tuple[chekcingPostion['active']]) !== 1) {
                        this.snackBarDanger(`Active should be 0 or 1 at line ${i+1}`)
                        this.csvUploading = false;                        
                        return 
                    } else if (isNaN(tuple[chekcingPostion['send_email']])) {
                        this.snackBarDanger(`Send_email should be integer at line ${i+1}`)
                        this.csvUploading = false;
                        return
                    }  else if (isNaN(tuple[chekcingPostion['email_gap']])) {
                        this.snackBarDanger(`Email_gap should be integer at line ${i+1}`)
                        this.csvUploading = false;
                        return
                    } else if (isNaN(tuple[chekcingPostion['th']])) {
                        this.snackBarDanger(`Th should be integer at line ${i+1}`)
                        this.csvUploading = false;
                        return
                    } 
                    // check last column data
                    for (let ele of  tuple[chekcingPostion['server']].split(',')) {
                        // console.log(ele)
                        // return
                        if (isNaN(ele)) {
                            this.snackBarDanger(`Server should be integer or integer at line ${i+1}`)
                            this.csvUploading = false;
                            return
                        } 
                    }
                }
            }
            return true;
        },
        submitFile () {
            var formData = new FormData();
            formData.append('file', this.uploadedFiles[0]);
            formData.append('broker_name', this.selectedBroker)
            console.log(this.selectedBroker)

            if (!this.selectedBroker) {
                console.log('broker name is empty !!!!');
                return;
            }

            apiAlertConfigTickCheck.csvUpload(formData).then(res => {
                // this.snackBarSuccess('Upload file successfuly');
                console.log(res.data)
            }).then( () => {
                this.snackBarSuccess('Upload file successfuly');
                this.csvUploading = false;
            }).catch( () => {
                this.csvUploading = false;
            })
    },
    }
};
</script>

<style scoped></style>