/**
 * The global tick alert config notification
 * All missing tick symbol configuration will be shown via this component.
 * This component will be loaded once user enter alert overview page
 */
<template>
	<v-alert dense prominent type="error" v-if="alertUnmatchedTick.length != 0">
      	<v-row align="center">
	        <v-col class="grow">
	        	<h4>You Have Missing Tick Config</h4>
	        	<p class="mb-2" style="white-space: nowrap; width: 900px; overflow: hidden;">
	        		<span v-for="item in alertUnmatchedTick" :key="item">{{ item }}, </span>
		        </p>
			</v-col>
	        <v-col class="shrink">
          		<v-btn v-on:click="triggerTickConfigModal()">Take Action</v-btn>
	        </v-col>
      	</v-row>
    </v-alert>
</template>
<script>
import * as apiSettingsUnmatchedTick from "@components/api/pk/settings/settings-unmatched-tick";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import * as apiBook from "@components/api/pk/book";

export default {
    props: {
        selectedBroker: { default: "", type: String }
    },
    components: {},
    mixins: [helper, snackbar],
    data () {
        return { alertUnmatchedTick: [] };
    },
    watch: {},
    methods: {
        /**
		 * open tick conf window
		 * @return  {[type]}  [return description]
		 */
        triggerTickConfigModal () {
            const data = { status: true, name: "tick_check", broker: this.selectedBroker, search: "-1" };
            this.$store.commit("triggerTickConfig", data);
        },
        /**
         * Load new added tick configuration
         * @return  {[type]}  [return description]
         */
        loadUnmatchedTick () {
            const self = this;
            const params = { broker_id: self.getBrokerIdByName(self.selectedBroker) };
            apiSettingsUnmatchedTick.fetch(params).then(res => {
                self.alertUnmatchedTick = res.data;
            });
        }
    },
    mounted () {
        // load checked result
        this.loadUnmatchedTick();
    }
};
</script>
