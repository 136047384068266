<template>
    <div class="home">
        <v-card>
            <GlobalNotificationAlert :selectedBroker="selectedBroker" v-if="loadGlobalAlert" />

            <v-container fluid>
                <v-row><v-col cols="12"></v-col></v-row>
                <v-row>
                    <v-col cols="12">
                        <v-tabs>
                            <v-tab v-on:click="changeView(1)" v-if="loadAlert == 1">Overview</v-tab>
                            <v-tab v-on:click="changeView(2)" v-if="loadHistory == 1">History</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>

                <Alert v-if="selectedModule == 1" @changeBroker="changeBroker" @reloadTickGlobalAlert="reloadTickGlobalAlert"/>
                <History v-if="selectedModule == 2" />

            </v-container>
        </v-card>
    </div>
</template>
<script>
import Alert from "./Alert";
import History from "./History";
import { permission } from "@components/mixins/permission";
import GlobalNotificationAlert from "@components/ComponentGlobalNotificationAlert";

export default {
    components: {
        Alert, History, GlobalNotificationAlert
    },
    mixins: [permission],
    data () {
        return {
            selectedModule: 1,
            loadAlert: 0,
            loadHistory: 0,
            selectedBroker: "",
            loadGlobalAlert: false
        };
    },
    methods: {
        changeView (key) {
            this.selectedModule = key;
        },
        reloadTickGlobalAlert () {
            this.loadGlobalAlert = false;
            this.$nextTick(() => {
                this.loadGlobalAlert = true;
            });
        },
        changeBroker (broker) {
            this.selectedBroker = broker;
            this.loadGlobalAlert = false;
            this.$nextTick(() => { this.loadGlobalAlert = true; });
        }
    },
    mounted () {
        const userFunction = this.getFunction("alert");

        if (userFunction.includes("overview")) {
            this.loadAlert = 1;
        }
        if (userFunction.includes("history")) {
            this.loadHistory = 1;
        }
    }
};
</script>
