<template lang="">
    <div :id="id">

        <v-card outlined>
            <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                    {{ title }}
                </div>
                <div class="text-right" style="margin-right: 0; width: 50%;" >
                    <download-excel :data="Object.values(apiDataSource)" :fields="csvFields" :name="broker + '_' + name "
                        style="display: inline">
                        <v-btn color="green" class="accent-4" icon small>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon  color="green" dark v-bind="attrs" v-on="on" dense>
                                        mdi-microsoft-excel
                                    </v-icon>
                                </template>
                                <span>Download As Excel</span>
                            </v-tooltip>
                        </v-btn>
                    </download-excel>
                    <div style="display: inline;">
                        <v-btn color="primary" class="accent-4" icon small v-on:click="openConfig()">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on"  dense>mdi-cog</v-icon>
                                </template>
                                <span>Settings</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                    <div v-on:click="openGlobalFilter()" style="display: inline">

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="red" dark v-bind="attrs" v-on="on" >mdi-filter</v-icon>
                            </template>
                            <span>Filter</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-card-title>
            <v-col lg="12" md="12" xs="12" cols="12" class="pr-0 pl-0">
                <v-data-table dense :search="search" :headers="dataHeaders" :items="filteredDataSource"
                    class="elevation-0 alert-datatable" v-if="name != 'tick_check'"
                    :items-per-page="itemsPerPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :loading="loading"
                    loading-text="Loading... Please wait" :height="230" fixed-header :hide-default-footer="true" @click:row="rowClick">
                    <!-- PK表column的filter -->
                    <template v-slot:top>
                        <v-row class="mb-1 custom-alert-table-header">
                            <!-- <div class="ml-5"></div> -->
                            <v-col cols="12" class="pl-6 pb-0">
                                <!-- <v-tabs dense>
                                    <v-tab v-for="item in dataHeaders" :key="item.value" v-if="item.value != 'message'">
                                        <v-checkbox class="home-checkbox mr-1 mb-0 mt-0" dense
                                            v-model="item.align == 'left'" :label="item.text" style="display: inline-block;"
                                            v-on:click="checkHeader(item)"></v-checkbox>
                                    </v-tab>
                                </v-tabs> -->
                                <v-slide-group show-arrows v-if="update">
                                    <v-checkbox class="home-checkbox mr-1 mb-0 mt-0" dense
                                        v-model="item.align == 'left'" :label="item.text" style="display: inline-block;"
                                        v-for="item in dataHeaders" :key="item.value" v-if="item.value != 'message'"
                                        v-on:click="checkHeader(item)"></v-checkbox>
                                </v-slide-group>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:item="item">
                        <tr :class="{'v-data-table__selected': checkHighlight(item.item, highlight)}">
                            <td v-if="showAlertRow(item.item, 'time')">{{ extractTime(item.item.time) }}</td>
                            <td v-if="showAlertRow(item.item, 'login')">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <a v-bind="attrs" v-on="on">{{ item.item.login }}</a>
                                    </template>
                                    <div style="background-color: #363636;">
                                        <v-btn small text color="primary" outlined class="ma-2"
                                            v-if="alertDialogLoginStatistic == 1" v-on:click="toggleDialogLoginTrades(item.item)">
                                            Open Trades
                                        </v-btn>
                                        <v-btn small text color="primary" outlined class="ma-2"
                                            v-if="alertDialogLoginOpenTrades == 1" v-on:click="toggleDialogLoginBrief(item.item)">
                                            Info
                                        </v-btn>
                                        <v-btn small text color="primary" outlined class="ma-2"
                                            v-if="alertDialogLoginInfo == 1" v-on:click="toggleDialogLoginStatistic(item.item)">
                                            Statistic
                                        </v-btn>
                                    </div>
                                </v-menu>
                            </td>
                            <td v-if="showAlertRow(item.item, 'orderOpenTime')">
                                {{ item.item.orderOpenTime }}
                            </td>
                            <td v-if="showAlertRow(item.item, 'book')">{{ item.item.book }}</td>
                            <td v-if="showAlertRow(item.item, 'symbol')">{{ item.item.symbol }}</td>
                            <td v-if="showAlertRow(item.item, 'volume')">{{ item.item.volume.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'order')">{{ item.item.order }}</td>
                            <td v-if="showAlertRow(item.item, 'direction')">{{ item.item.direction }}</td>
                            <td v-if="showAlertRow(item.item, 'group')">{{ item.item.group }}</td>
                            <td v-if="showAlertRow(item.item, 'type')">{{ item.item.type }}</td>
                            <td v-if="showAlertRow(item.item, 'currentValue')">{{ item.item.currentValue.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'threshold')">{{ item.item.threshold }}</td>
                            <td v-if="showAlertRow(item.item, 'profitToday')">{{ item.item.profitToday.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'lockingPosition')">{{ item.item.lockingPosition.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'balance')">{{ item.item.balance.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'credit')">{{ item.item.credit.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'equity')">{{ item.item.equity.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'exposure')">{{ item.item.exposure.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'ticket')">{{ item.item.ticket }}</td>
                            <td v-if="showAlertRow(item.item, 'price')">{{ item.item.price }}</td>
                            <td v-if="showAlertRow(item.item, 'orderTicket')">{{ item.item.orderTicket }}</td>
                            <td v-if="showAlertRow(item.item, 'now')">{{ item.item.now }}</td>
                            <td v-if="showAlertRow(item.item, 'gap')">{{ item.item.gap }}</td>
                            <td v-if="showAlertRow(item.item, 'lastUpdateTime')">{{ item.item.lastUpdateTime }}</td>
                            <td v-if="showAlertRow(item.item, 'local_time')">{{ item.item.local_time }}</td>
                            <td v-if="showAlertRow(item.item, 'event')">{{ item.item.event }}</td>
                            <td v-if="showAlertRow(item.item, 'totalVolume')">{{ item.item.totalVolume.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'profit')">{{ item.item.profit.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'volumeToday')">{{ item.item.volumeToday.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'amount')">{{ item.item.amount.toFixed(2) }}</td>
                            <td v-if="showAlertRow(item.item, 'thredshold_amount')">{{ item.item.thredshold_amount }}</td>
                        </tr>
                    </template>
                    <template v-slot:header.time="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small :color="(timeRange[0] !=timeMin||timeRange[1]!=timeMax) ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 500px; ">
                            <v-card flat>
                                <!-- <v-subheader>Min and max range slider</v-subheader> -->
                                <v-card-text>
                                    <v-row>
                                        <v-col class="px-4">
                                            <v-range-slider v-model="timeRange" :max="timeMax" :min="timeMin" hide-details class="align-center">
                                                <template v-slot:prepend>
                                                    <v-text-field :value="extractTime(timehelper(timeRange[0]))" class="mt-0 pt-0" hide-details single-line disabled dense
                                                        style="width: 115px" @change="$set(timeRange, 0, $event)"></v-text-field>
                                                </template>
                                                <template v-slot:append>
                                                    <v-text-field :value="extractTime(timehelper(timeRange[1]))" class="mt-0 pt-0" hide-details single-line disabled dense
                                                        style="width: 115px" @change="$set(timeRange, 1, $event)"></v-text-field>
                                                </template>
                                            </v-range-slider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                    <v-btn @click="timeRange = [timeMin,timeMax]" small text color="primary" class="ml-2 mb-2">
                                        Reset
                                    </v-btn>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.login="{ header }" >
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`loginName`)">
                                    <v-icon small :color="loginName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="loginName" class="px-4" type="text" ref="loginName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="loginName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.book="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`bookName`)">
                                    <v-icon small :color="bookName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="bookName" class="px-4" type="text" ref="bookName"
                                    label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="bookName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.symbol="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`symbolName`)">
                                    <v-icon small :color="symbolName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="symbolName" class="px-4" type="text" ref="symbolName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="symbolName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.order="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`orderName`)">
                                    <v-icon small :color="orderName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="orderName" class="px-4" type="text" ref="orderName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="orderName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.ticket="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`ticketName`)">
                                    <v-icon small :color="ticketName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="ticketName" class="px-4" type="text" ref="ticketName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="ticketName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.orderTicket="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`orderTicketName`)">
                                    <v-icon small :color="orderTicketName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="orderTicketName" class="px-4" type="text" ref="orderTicketName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="orderTicketName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.event="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`eventName`)">
                                    <v-icon small :color="eventName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="eventName" class="px-4" type="text" ref="eventName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="eventName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.group="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`groupName`)">
                                    <v-icon small :color="groupName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="groupName" class="px-4" type="text" ref="groupName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="groupName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.type="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`typeName`)">
                                    <v-icon small :color="typeName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="typeName" class="px-4" type="text" ref="typeName"
                                            label="Enter the search term" style="width: 100%;"></v-text-field>
                                <v-btn @click="typeName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.direction="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" >
                                    <v-icon small :color="directionName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 150px">
                                <v-container class="pb-0 pt-1" fluid>
                                    <v-radio-group v-model="directionName" class="mt-0 mb-0 pb-0">
                                        <v-radio label="Long" value="long"></v-radio>
                                        <v-radio label="Short" value="short"></v-radio>
                                    </v-radio-group>
                                </v-container>
                                <v-btn @click="directionName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean</v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.volume="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`volumeName`)">
                                    <v-icon small :color="volumeName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="volumeName" class="px-4" type="text" ref="volumeName"
                                            label="Volume >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="volumeName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.profitToday="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`pnlName`)">
                                    <v-icon small :color="pnlName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="pnlName" class="px-4" type="text" ref="pnlName"
                                            label="PNL >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="pnlName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.lockingPosition="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`lockingPositionName`)">
                                    <v-icon small :color="lockingPositionName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="lockingPositionName" class="px-4" type="text" ref="lockingPositionName"
                                            label="Lock Position >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="lockingPositionName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.totalVolume="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }" @click="setFocus(`totalVolumeName`)">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small :color="totalVolumeName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="totalVolumeName" class="px-4" type="text" ref="totalVolumeName"
                                            label="Total Volume >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="totalVolumeName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.currentValue="{ header }">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`currentValueName`)">
                                    <v-icon small :color="currentValueName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="currentValueName" class="px-4" type="text" ref="currentValueName"
                                            label="Value >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="currentValueName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.threshold="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`thresholdName`)">
                                    <v-icon small :color="thresholdName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="thresholdName" class="px-4" type="text" ref="thresholdName"
                                            label="Threshold >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="thresholdName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.balance="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`balanceName`)">
                                    <v-icon small :color="balanceName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="balanceName" class="px-4" type="text" ref="balanceName"
                                            label="Balance <=" style="width: 100%;"></v-text-field>
                                <v-btn @click="balanceName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.credit="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`creditName`)">
                                    <v-icon small :color="creditName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="creditName" class="px-4" type="text" ref="creditName"
                                            label="Credit >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="creditName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.equity="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`equityName`)">
                                    <v-icon small :color="equityName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="equityName" class="px-4" type="text" ref="equityName"
                                            label="Equity >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="equityName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.exposure="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`exposureName`)">
                                    <v-icon small :color="exposureName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="exposureName" class="px-4" type="text" ref="exposureName"
                                            label="Equity >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="exposureName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.price="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`priceName`)">
                                    <v-icon small :color="priceName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="priceName" class="px-4" type="text" ref="priceName"
                                            label="Price >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="priceName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.profit="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`profitName`)">
                                    <v-icon small :color="profitName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="profitName" class="px-4" type="text" ref="profitName"
                                            label="Profit >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="profitName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.amount="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`amountName`)">
                                    <v-icon small :color="amountName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="amountName" class="px-4" type="text" ref="amountName"
                                            label="Amount >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="amountName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.volumeToday="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`volumeTodayName`)">
                                    <v-icon small :color="volumeTodayName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="volumeTodayName" class="px-4" type="text" ref="volumeTodayName"
                                            label="Vol Today >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="volumeTodayName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                    <template v-slot:header.thredshold_amount="{ header }" v-if="name != 'tick_check'">
                        {{ header.text }}
                        <v-menu offset-y :close-on-content-click="false" top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`thredsholdAmountName`)">
                                    <v-icon small :color="thredsholdAmountName ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: #1e1e1e; width: 280px; ">
                                <v-text-field v-model="thredsholdAmountName" class="px-4" type="text" ref="thredsholdAmountName"
                                            label="Thredshold Amount >=" style="width: 100%;"></v-text-field>
                                <v-btn @click="thredsholdAmountName = ''" small text color="primary" class="ml-2 mb-2">
                                    Clean
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                </v-data-table>

                <v-data-table dense :search="search" :headers="dataHeaders" :items="apiDataSource"
                    class="elevation-0 alert-datatable" v-if="name === 'tick_check'"
                    :items-per-page="itemsPerPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :loading="loading"
                    loading-text="Loading... Please wait" :height="230" fixed-header :hide-default-footer="true">
                    <!-- PK表column的filter -->

                    <template v-slot:item="item">
                        <tr>
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on"
                                            class="hover-login-tooltip"
                                            v-on:mouseover="loadSymbolInfo(item.item.symbol, item.item.server)">
                                            {{ item.item.symbol }}
                                        </span>
                                    </template>
                                    <span v-html="formatTickSymbolDetail()"></span>
                                </v-tooltip>
                            </td>
                            <td>{{ item.item.now }}</td>
                            <td>{{ item.item.gap }}</td>
                            <td>{{ item.item.lastUpdateTime }}</td>
                            <td>{{ item.item.threshold }}</td>
                            <td>{{ item.item.server }}</td>
                        </tr>
                    </template>


                </v-data-table>

                <v-dialog v-model="alertSettings.visible" max-width="800">
                    <v-card>
                        <v-card-title class="headline">{{ alertSettings.title }}</v-card-title>
                        <v-card-actions>
                            <v-row v-if="name == 'big_lot'">
                                <v-col cols="12"></v-col>
                            </v-row>
                        </v-card-actions>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="alertSettings.visible = false">Cancel</v-btn>
                            <v-btn color="green darken-1" text @click="" :loading="alertSettings.loading">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogLoginTrades.visible" :fullscreen="dialogLoginTrades.fullscreen"
                    width="unset" :hide-overlay="true" :persistent="true">
                    <v-card class="pt-0">

                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);" >
                            <!-- <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon> -->

                            <v-toolbar-title class="text-h6 white--text pl-0">
                                {{ dialogLoginTrades.title }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn color="white" icon @click="dialogLoginTrades.fullscreen = !dialogLoginTrades.fullscreen">
                                <v-icon>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn color="white" icon @click="dialogLoginTrades.visible = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <!-- <v-card-title class="headline">{{ dialogLoginTrades.title }}</v-card-title> -->
                        <v-card-actions>
                            <v-data-table :headers="dialogLoginTrades.headers" :items="dialogLoginTrades.dataSource"
                                dense :items-per-page="-1" :loading="dialogLoginTrades.loadingTable" height="200"
                                :hide-default-footer="true" fixed-header>

                                <template v-slot:item.OpenTime="{ item }">
                                    {{ item.OpenTime.replace("%", "") }}
                                </template>

                                <template v-slot:item.Cmd="{ item }">
                                    <span v-if="item.Cmd == 0">Buy</span>
                                    <span v-if="item.Cmd == 1">Sell</span>
                                    <span v-if="item.Cmd == 2">Buy Limit</span>
                                    <span v-if="item.Cmd == 3">Sell Limit</span>
                                    <span v-if="item.Cmd == 4">Buy Stop</span>
                                    <span v-if="item.Cmd == 5">Sell Stop</span>
                                </template>

                                <template v-slot:item.Reason="{ item }">
                                    <span v-if="item.Reason == 0">Client</span>
                                    <span v-if="item.Reason == 1">Expert</span>
                                    <span v-if="item.Reason == 2">Dealer</span>
                                    <span v-if="item.Reason == 3">Signal</span>
                                    <span v-if="item.Reason == 4">Gateway</span>
                                    <span v-if="item.Reason == 5">Mobile</span>
                                    <span v-if="item.Reason == 6">Web</span>
                                    <span v-if="item.Reason == 7">API</span>
                                </template>
                            </v-data-table>
                        </v-card-actions>

                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialogLoginTrades.visible = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogLoginStatistic.visible" :fullscreen="dialogLoginStatistic.fullscreen"
                    width="1000" :hide-overlay="true" :persistent="true" height="500">
                    <v-card class="pt-0">

                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);" >

                            <v-toolbar-title class="text-h6 white--text pl-0">{{ dialogLoginStatistic.title }}</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn color="white" icon @click="dialogLoginStatistic.fullscreen = !dialogLoginStatistic.fullscreen">
                                <v-icon>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn color="white" icon @click="dialogLoginStatistic.visible = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-col cols="12">
                            <LoginHistoryChart :dom="'alert-' + dialogLoginStatistic.login"
                                :timeData="LoginHistoryChartTimeDate" :loginValue="loginValue"
                                :legendData="LoginHistoryChartLegendData" :seriesData="LoginHistoryChartSeriesData" />
                        </v-col>

                        <!--
                        <v-card-actions>
                            <LoginHistoryChart :dom="'alert-' + dialogLoginStatistic.login"
                                :timeData="LoginHistoryChartTimeDate"
                                :loginValue="loginValue" :legendData="LoginHistoryChartLegendData"
                                :seriesData="LoginHistoryChartSeriesData" />
                        </v-card-actions>
                         -->

                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialogLoginStatistic.visible = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogLoginBrief.visible" :fullscreen="dialogLoginBrief.fullscreen"
                    width="400" :hide-overlay="true" :persistent="true">
                    <v-card>

                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);">
                            <!-- <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon> -->

                            <v-toolbar-title class="text-h6 white--text pl-0">
                                {{ dialogLoginBrief.title }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn color="white" icon @click="dialogLoginBrief.fullscreen = !dialogLoginBrief.fullscreen">
                                <v-icon>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn color="white" icon @click="dialogLoginBrief.visible = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <!-- <v-card-title class="headline">{{ dialogLoginTrades.title }}</v-card-title> -->
                        <v-card-actions>

                            <div v-html="dataDisplayApr(dialogLoginBrief.data)"></div>
                           <!--  <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title v-for="(value, name, index) in dialogLoginBrief.data" :key="index">
                                        {{ name }}: {{ value }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->
                        </v-card-actions>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialogLoginBrief.visible = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-col>
        </v-card>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import * as apiAlertData from "@components/api/pk/alert-data";
import * as apiAlertDataTick from "@components/api/pk/alert-data-tick";
import * as apiSymbolInfo from "@components/api/pk/symbol/symbol-info";
import { alert } from "@components/mixins/alert";
import { helper } from "@components/mixins/helper";
import { resize } from "@components/mixins/resize";
import { permission } from "@components/mixins/permission";
import { dataDisplay } from "@components/mixins/data-display";
import * as apiLoginHistory from "@components/api/pk/login-history";
import LoginHistoryChart from "../../home/components/ComponentLoginHistory";

import * as apiManagerTradesOpen from "@components/api/pk/manager/trades-open";
import * as apiLoginDetail from "@components/api/pk/login-detail";
import $ from "jquery";
// import "jquery-ui";

export default {
    components: { LoginHistoryChart },
    mixins: [alert, helper, resize, dataDisplay, permission],
    props: {
        highlight: {},
        customHeight: 120,
        name: "",
        dataSource: null,
        itemsPerPage: null,
        broker: null,
        headers: null,
        brokerId: null,
        id: null
    },
    data () {
        return {
            timer: 0,
            // headerFilterList: ['loginName','bookName','symbolName','orderName','ticketName','eventName','groupName','typeName','volumeName','pnlName','lockingPositionName','totalVolumeName','currentValueName','thresholdName'],

            loginName: "",
            bookName: "",
            symbolName: "",
            orderName: "",
            groupName: "",
            directionName: "",
            volumeName: "",
            totalVolumeName: "",
            pnlName: "",
            lockingPositionName: "",
            currentValueName: "",
            typeName: "",
            ticketName: "",
            orderTicketName: "",
            eventName: "",
            thresholdName: "",
            exposureName: "",
            priceName: "",
            profitName: "",
            volumeTodayName: "",
            thredsholdAmountName: "",
            amountName: "",
            balanceName: "",
            creditName: "",
            equityName: "",
            timeMin: 20,
            timeMax: 50,
            timeRange: [20, 50],
            timePeriod: [],
            alertDialogLoginStatistic: 1,
            alertDialogLoginOpenTrades: 1,
            alertDialogLoginInfo: 1,

            LoginHistoryChartTimeDate: [],
            loginValue: "",
            LoginHistoryChartSeriesData: [],
            LoginHistoryChartLegendData: [],
            dialogLoginStatistic: {
                login: 1,
                loadChart: 0,
                fullscreen: false,
                data: {},
                visible: false,
                title: "Account Brief "
            },
            dialogLoginBrief: {
                fullscreen: false,
                data: {},
                visible: false,
                title: "Account Brief "
            },
            dialogLoginTrades: {
                fullscreen: false,
                visible: false,
                title: "Open Trades ",
                headers: [
                    // { text: "Login", value: "Login" },
                    { text: "Symbol", value: "Symbol", align: "left", width: 120 },
                    { text: "Type", value: "Cmd", align: "left", width: 100 },
                    { text: "Ticket", value: "Order", align: "left" },
                    { text: "Open Time", value: "OpenTime", align: "left", width: 190 },
                    { text: "Open Price", value: "OpenPrice", align: "left", width: 120 },
                    { text: "Volume", value: "Volume", align: "left" },
                    { text: "Tp", value: "Tp", align: " d-none" },
                    { text: "Sl", value: "Sl", align: " d-none" },
                    { text: "Profit", value: "Profit", align: "left" },
                    { text: "Comment", value: "Comment", align: "left" },
                    { text: "Commission", value: "Commission", align: " d-none" },
                    { text: "Magic", value: "Magic", align: "left" },
                    { text: "Reason", value: "Reason", align: "left" },
                    { text: "Swap", value: "Storage", align: " d-none" }
                ],
                dataSource: []
            },
            panelDefault: [0],
            keyAlertExcluded: ["alert_name", "broker", "message"],
            keyAlertMapName: {
                book: "Book",
                direction: "Direction",
                group: "Group",
                login: "Login",
                order: "Ticket",
                symbol: "Symbol",
                volume: "Volume",
                time: "Time",
                currentValue: "Value",
                threshold: "Threshold",
                type: "Type",
                profitToday: "Profit Today",
                threshold_pnl: "Threshold PNL",
                lockingPosition: "Locking Position",
                threshold_volume: "Threshold Volume",
                balance: "Balance",
                equity: "Equity",
                credit: "Credit",
                exposure: "Exposure",
                ticket: "Ticket",
                threshold_interval: "Threshold Interval",
                threshold_number: "Threshold Number",
                totalVolume: "Total Volume",
                event: "Event",
                isClosed: "Close Order"
            },
            keyAlertDecimal: ["currentValue", "profitToday", "lockingPosition", "balance", "equity", "credit", "totalVolume"],
            alertSettings: { loading: false, visible: false, title: "" },
            sortBy: "time",
            sortDesc: true,
            search: "",
            loading: false,
            reloadTable: false,
            title: "",
            dataHeaders: [],
            csvFields: {},
            apiDataSource: [],
            bigLotFilter: { filterLogin: -1, filterBook: "", filterSymbol: "", filterVol: -1 },
            hello: 1,
            alertDescription: {
                big_lot: "Sample Description",
                mandate: "Sample Description",
                profit_taker: "Sample Description",
                locking_position: "Sample Description",
                large_exposure: "Sample Description",
                trade_on_credit: "Sample Description",
                fast_trade: "Sample Description",
                watch_list: "Sample Description",
                tick_check: "Sample Description",
                same_direction: "Sample Description",
                volume_alert: "Sample Description",
                withdraw_deposit: "Sample Description"
            },
            update: true, 
            tickSymbolInfo: []
        };
    },
    computed: {
        ...mapState(["alertHeader", "brokerMap"]),
        timenowServer () {
            // return this.$store.getters.getCurrentTimeServer;
            return this.$store.state.currentTimeServer;
        },
        highlightData () {
            if (this.highlight.hasOwnProperty("data")) {
                return this.highlight.data;
            }
            return "{}";
        },
        computedTableHeader () {
            return this.$store.state.alertHeader;
        },
        /**
         * 表头的filter
         *
         * @return  {[type]}  [return description]
         */
        filteredDataSource () {
            const conditions = [];
            if (this.name == "tick_check") return this.apiDataSource;
            if (this.loginName) conditions.push(this.filterLoginName);
            if (this.bookName) conditions.push(this.filterBookName);
            if (this.symbolName) conditions.push(this.filterSymbolName);
            if (this.orderName) conditions.push(this.filterOrderName);
            if (this.ticketName) conditions.push(this.filterTicketName);
            if (this.orderTicketName) conditions.push(this.filterOrderTicketName);
            if (this.eventName) conditions.push(this.filterEventName);
            if (this.directionName) conditions.push(this.filterDirectionName);
            if (this.groupName) conditions.push(this.filterGroupName);
            if (this.volumeName) conditions.push(this.filterVolumeName);
            if (this.totalVolumeName) conditions.push(this.filterTotalVolumeName);
            if (this.pnlName) conditions.push(this.filterPnlName);
            if (this.lockingPositionName) conditions.push(this.filterLockingPositionName);
            if (this.currentValueName) conditions.push(this.filterCurrentValueName);
            if (this.typeName) conditions.push(this.filterTypeName);

            if (
                this.timeRange[0] != this.timeMin || this.timeRange[1] != this.timeMax
            ) {
                conditions.push(this.filterTimeRange);
            }
            if (this.thresholdName) conditions.push(this.filterThresholdName);
            if (this.exposureName) conditions.push(this.filterExposureName);
            if (this.equityName) conditions.push(this.filterEquityName);
            if (this.creditName) conditions.push(this.filterCreditName);
            if (this.balanceName) conditions.push(this.filterBalanceName);
            if (this.priceName) conditions.push(this.filterPriceName);
            if (this.profitName) conditions.push(this.filterProfitName);
            if (this.volumeTodayName) conditions.push(this.filterVolumeTodayName);
            if (this.amountName) conditions.push(this.filterAmountName);
            if (this.thredsholdAmountName) conditions.push(this.filterThredsholdAmountName);

            if (conditions.length > 0) {
                return this.apiDataSource.filter((item) => {
                    return conditions.every((condition) => {
                        return condition(item);
                    });
                });
            }
            return this.apiDataSource;
        }
    },
    watch: {
        search (nv, ov) {}
    },
    methods: {
        /**
         * Format call back result of tick symbol info
         *
         * @return  {[type]}  [return description]
         */
        formatTickSymbolDetail() {
            if (this.tickSymbolInfo.length == 0) {
                return "";
            }
            let result = "<p class=\"mb-1\">Symbol Info</p>\n";
            result += "<p class=\"mb-1\">Contract Size: " + this.tickSymbolInfo[0].contract_size.toString() + "</p>\n";
            result += "<p class=\"mb-1\">Security: " + this.tickSymbolInfo[0].security.toString() + "</p>\n";

            return result;
        },
        /**
         * Load symbol basic information
         *
         * @return  {[type]}  [return description]
         */
        loadSymbolInfo(symbol, server) {
            let self = this;

            const params = { symbol: symbol, server: server, broker_name: self.broker };
            self.tickSymbolInfo = [];
            apiSymbolInfo.fetch(params).then(res => {
                self.tickSymbolInfo = res.data;
            })
        },
        /**
         * load login's statistic
         * @param   {[type]}  item  [item description]
         * @return  {[type]}        [return description]
         */
        loadLoginHistoryChart (item) {
            const self = this;
            const params = { login: item, broker_id: self.brokerId };

            self.loginValue = item;

            apiLoginHistory.fetch(params).then((res) => {
                console.log(res.data);
                self.LoginHistoryChartSeriesData = [];
                self.LoginHistoryChartLegendData = [];
                self.LoginHistoryChartTimeDate = res.data.xdata;
                if (self.LoginHistoryChartTimeDate != undefined) {
                    self.LoginHistoryChartTimeDate =
                        self.LoginHistoryChartTimeDate.map(function (str) {
                        	return str.substring(2, str.length - 9);
                        });

                    const daily_pnl = {
                        name: "Daily PNL",
                        type: "line",
                        symbolSize: 8,
                        // hoverAnimation: false,
                        emphasis: { scale: false },
                        symbol: "circle",
                        yAxisIndex: 2,
                        data: res.data.ydata.daily_pnl
                    };
                    const balance = {
                        name: "Balance",
                        type: "line",
                        emphasis: { scale: false },
                        symbol: "circle",
                        data: res.data.ydata.balance
                    };
                    const equity = {
                        name: "Equity",
                        type: "line",
                        emphasis: { scale: false },
                        symbol: "circle",
                        data: res.data.ydata.equity
                    };
                    const dpm = {
                        name: "DPM",
                        type: "bar",
                        emphasis: { scale: false },
                        symbol: "circle",
                        yAxisIndex: 1,
                        data: res.data.ydata.dpm
                    };

                    self.LoginHistoryChartLegendData.push(daily_pnl.name);
                    self.LoginHistoryChartSeriesData.push(daily_pnl);
                    self.LoginHistoryChartLegendData.push(balance.name);
                    self.LoginHistoryChartSeriesData.push(balance);
                    self.LoginHistoryChartLegendData.push(equity.name);
                    self.LoginHistoryChartSeriesData.push(equity);
                    self.LoginHistoryChartLegendData.push(dpm.name);
                    self.LoginHistoryChartSeriesData.push(dpm);
                }
                setTimeout(() => {
                    // self.$nextTick(() => {
                    self.dialogLoginStatistic.loadChart = 1;
                    // })
                }, 1000);

                // console.log(self.LoginHistoryChartTimeDate)
            });
        },
        /**
         * 获取选中账号的apr数据
         * @return {[type]} [description]
         */
        loginDetailHandler () {
            const self = this;

            return {
                // 调用特定账号的summary信息
                load (login) {
                    const params = { login: login };
                    apiLoginDetail.fetch(params).then((res) => {
                        if (res.data.length != 0) {
                            self.dialogLoginBrief.data = res.data[0];
                        }
                    });
                }
            };
        },
        /**
         * 获取账号brief info
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        toggleDialogLoginBrief (data) {
            const login = data.login;
            this.dialogLoginBrief.title = "Info - " + login.toString();
            this.dialogLoginBrief.visible = true;
            this.dialogLoginBrief.fullscreen = false;
            this.dialogLoginBrief.data = {};
            this.loginDetailHandler().load(data.login);
        },
        toggleDialogLoginStatistic (data) {
            const login = data.login;
            this.dialogLoginStatistic.title = "Snapshot - " + login.toString();
            this.dialogLoginStatistic.visible = true;
            this.dialogLoginStatistic.fullscreen = false;
            this.dialogLoginStatistic.data = [];
            this.dialogLoginStatistic.loadChart = 0;
            this.dialogLoginStatistic.login = login;
            this.loadLoginHistoryChart(login);
        },
        /**
         * 控制alert login点击后的dialog
         * @return {[type]} [description]
         */
        toggleDialogLoginTrades (data) {
            const self = this;
            const login = data.login;
            this.dialogLoginTrades.visible = true;
            this.dialogLoginTrades.title = "Open Trades - " + login.toString();
            this.dialogLoginTrades.loadingTable = true;
            this.dialogLoginTrades.fullscreen = false;
            this.dialogLoginTrades.dataSource = [];
            const loginArray = [login];
            const params = { login: loginArray, broker_id: self.brokerId };

            apiManagerTradesOpen.create(params).then((res) => {
                console.log(res);
                if (res.data.hasOwnProperty(login)) {
                    if (res.data[login].hasOwnProperty("raw")) {
                        const raw = res.data[login].raw;
                        self.dialogLoginTrades.dataSource = raw;
                    }
                }
                self.dialogLoginTrades.loadingTable = false;
            });

            // this.bindResize(document.getElementsByClassName('.v-dialog--active'));
            this.bindResize($(".v-dialog--active"));

            // console.log($('.v-dialog--active'));
            // console.log($.resizable());
            // $('.v-dialog--active').resizable();
        },
        /**
         * 自定义表header的检查
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        checkHeader (data) {
            let status = "left";
            if (data.align == "left") status = " d-none";
            const params = {
                header: data.value,
                alert: this.name,
                status: status
            };
            this.$emit("checkHeader", params);
        },
        /**
         * 展示alert数据
         * @param  {[type]} item [description]
         * @param  {[type]} key  [description]
         * @return {[type]}      [description]
         */
        showAlertRow (item, key) {
            const alertName = item.alert_name;

            // 检查是否存在store里面
            if (this.$store.state.alertHeader.hasOwnProperty(alertName)) {
                const alertStructure = this.$store.state.alertHeader[alertName];
                const columnProperty = alertStructure.filter((item) => item.value == key);
                
                // if (alertName === "big_lot" && key === "orderOpenTime") {
                //     console.log("Show Alert Row: ", key, " ==> ", columnProperty, " ==> ", this.$store.state.alertHeader[alertName]);
                // }

                if (columnProperty.length == 0) return false;
                if (columnProperty[0].align == "left") return true;
            }
            
            return false;
        },
        rowClick (item, row) {
            row.select(true);
        },
        formatValue (i, detail) {
            if (this.keyAlertDecimal.includes(i)) {
                return detail.toFixed(2);
            }

            return detail;
        },
        formatKeyName (key) {
            if (this.keyAlertMapName.hasOwnProperty(key)) {
                return this.keyAlertMapName[key];
            }

            return key;
        },
        diffInSecond (last, now) {
            const lastDt = new Date(last);
            const nowDt = new Date(now);
            return nowDt.getTime() / 1000 - lastDt.getTime() / 1000;
        },
        // openConfig() {
        //     this.alertSettings.visible = true;
        //     this.alertSettings.title = this.title + " Data Display Config";
        // },
        openConfig () {
            this.$emit("openConfig", this.name, this.broker);
        },
        /**
         * 打开自定义用户警报filter
         * @return {[type]} [description]
         */
        openGlobalFilter () {
            this.$emit("openGlobalFilter", this.name, this.broker);
        },
        /**
         * 过滤当前历史的alert, 此过滤规则是用户自己定义并不是服务器端的规则
         * @param  {[type]} alert [description]
         * @param  {[type]} data  [description]
         * @return {[type]}       [description]
         */
        filterAlert (alert, data) {
            const key = "brokerRuleAlertFilter:" + this.broker;
            let filterData = localStorage.getItem(key);

            this.apiDataSource = data;
            if (filterData != undefined) {
                filterData = JSON.parse(filterData);
                const rule = filterData[alert];
                // if (alert === "big_lot") console.log("Rule: ", rule);
                if (rule != undefined) {
                    this.apiDataSource = this.alertGlobalFilterFrontend(rule, alert, data);
                }
            }

            if (alert === "big_lot") console.log("F: ", this.apiDataSource);

            for (const i of this.apiDataSource) {
                this.timePeriod.push(Date.parse(i.time));
            }
            this.timeMin = Math.min.apply(Math, this.timePeriod);
            this.timeMax = Math.max.apply(Math, this.timePeriod);
            this.timeRange = [this.timeMin, this.timeMax];
        },
        timehelper (time) {
            const timeString = new Date(time).toLocaleString("en-GB");
            const newTime =
                timeString.substr(6, 4) +
                "-" +
                timeString.substr(3, 2) +
                "-" +
                timeString.substr(0, 2) +
                " " +
                timeString.substr(12, 8);
            return newTime;
        },
        /**
         * 获取alert的历史缓存
         * @return {[type]} [description]
         */
        loadAlertDataTick () {
            const self = this;
            const params = { broker_id: self.brokerId, broker_name: self.getBrokerNameById(self.brokerId) };
            apiAlertDataTick.fetch(params).then((res) => {
                // console.log("Tick Alert Data: ", res);
                const key = "alertRemoteHistory:" + self.name;
                if (res.data.data_alert == null || JSON.stringify(res.data.data_alert) == "{}") {
                    self.apiDataSource = [];
                    localStorage.setItem(key, JSON.stringify([]));
                } else {
                    // 从缓存读取那些book在alert表需要隐藏不显示
                    localStorage.setItem(key, JSON.stringify(res.data.data_alert[self.name]));
                    const filterBrokerAlertGroupAll = localStorage.getItem("alertHideBroker");

                    // 初始化当前broker的book hide列表
                    let alertHide = [];

                    // 创建没有任何过滤的alert变量, api拿到的数据直接assign
                    let alertBeforeFilter = res.data.data_alert[self.name];

                    // 如果配置中有这个broker的列表存在
                    if (filterBrokerAlertGroupAll.hasOwnProperty(self.brokerId)) {
                        alertHide = filterBrokerAlertGroupAll[self.brokerId];

                        // 第一层过滤, alert book的filter
                        alertBeforeFilter = res.data.data_alert[self.name].filter((item) => !alertHide.includes(item.book));
                    }

                    // 进一步的进行filter
                    self.filterAlert(self.name, alertBeforeFilter);
                }
            });
        },
        /**
         * 获取alert数据
         * @return {[type]} [description]
         */
        loadAlertData () {
            const self = this;

            const param = { broker_id: self.brokerId, alert: self.name };

            self.loading = true;

            apiAlertData.fetch(param).then((res) => {
                // if (self.name === "big_lot") console.log(res);
                // console.log(self.name, " => ", res.data);
                const key = "alertRemoteHistory:" + self.name;

                if (res.data.data_alert == null || JSON.stringify(res.data.data_alert) == "{}") {
                    self.apiDataSource = [];
                    localStorage.setItem(key, JSON.stringify([]));
                } else {
                    // localStorage.setItem(key,JSON.stringify(res.data["data_alert"][self.name]));
                    // let alertHide = JSON.parse(localStorage.getItem("alertHide"));
                    // let alertBeforeFilter = res.data["data_alert"][self.name].filter((item) => !alertHide.includes(item.book));
                    // // let alertBeforeFilter = self.alertPermissionDataFilter(res.data["data_alert"][self.name]);
                    // self.filterAlert(self.name, alertBeforeFilter);

                    const brokerIdInString = self.brokerId.toString();

                    // 从缓存读取那些book在alert表需要隐藏不显示
                    localStorage.setItem(key, JSON.stringify(res.data.data_alert[self.name]));

                    let filterBrokerAlertGroupAll = {};
                    if (localStorage.getItem("alertHideBroker") != null && localStorage.getItem("alertHideBroker") != undefined) {
                        filterBrokerAlertGroupAll = JSON.parse(localStorage.getItem("alertHideBroker"));
                    }

                    // 初始化当前broker的book hide列表
                    let alertHide = [];

                    // 创建没有任何过滤的alert变量, api拿到的数据直接assign
                    let alertBeforeFilter = res.data.data_alert[self.name];

                    // 如果配置中有这个broker的列表存在
                    if (filterBrokerAlertGroupAll.hasOwnProperty(brokerIdInString)) {
                        alertHide = filterBrokerAlertGroupAll[brokerIdInString];

                        // console.log("Filter Hide: ", alertHide, " ==> ", filterBrokerAlertGroupAll, " ==> ", brokerIdInString, " ==> ", filterBrokerAlertGroupAll[brokerIdInString]);

                        // 第一层过滤, alert book的filter
                        alertBeforeFilter = res.data.data_alert[self.name].filter((item) => !alertHide.includes(item.book));
                    }

                    // if (self.name === 'big_lot') console.log(self.name);
                    // if (self.name === "big_lot") console.log(alertBeforeFilter);
                    // 进一步的进行filter
                    self.filterAlert(self.name, alertBeforeFilter);
                }

                self.loading = false;
                self.$nextTick(() => {});
            });
        },
        extractTime (time) {
            if (time == null || time == undefined) return "";
            return time.substring(5);
            // replace("2021-", "");
        },
        onClick () {
            this.panelDefault = [0];
            // const curr = this.panelDefault;
            // this.panelDefault = curr === undefined ? 0 : undefined;
        },
        checkSelected (item, highlight) {
            if (highlight.alertName == "big_lot") {
                console.log("Check Alert big lot");
            }
        },
        filterLoginName (item) {
            return item.login.toString().includes(this.loginName);
        },
        filterBookName (item) {
            return item.book
                .toLowerCase()
                .includes(this.bookName.toLowerCase());
        },
        filterSymbolName (item) {
            return item.symbol
                .toLowerCase()
                .includes(this.symbolName.toLowerCase());
        },
        filterTypeName (item) {
            return item.type
                .toLowerCase()
                .includes(this.typeName.toLowerCase());
        },
        filterOrderName (item) {
            return item.order.toString().includes(this.orderName);
        },
        filterTicketName (item) {
            return item.ticket.toString().includes(this.ticketName);
        },
        filterOrderTicketName (item) {
            return item.orderTicket.toString().includes(this.orderTicketName);
        },
        filterEventName (item) {
            return item.event.toString().includes(this.eventName);
        },
        filterDirectionName (item) {
            return item.direction
                .toLowerCase()
                .includes(this.directionName.toLowerCase());
        },
        filterGroupName (item) {
            return item.group
                .toLowerCase()
                .includes(this.groupName.toLowerCase());
        },
        filterVolumeName (item) {
            return item.volume >= this.volumeName;
        },
        filterTotalVolumeName (item) {
            return item.totalVolume >= this.totalVolumeName;
        },
        filterPnlName (item) {
            return item.profitToday >= this.pnlName;
        },
        filterLockingPositionName (item) {
            return item.lockingPosition >= this.lockingPositionName;
        },
        filterCurrentValueName (item) {
            return item.currentValue >= this.currentValueName;
        },
        filterThresholdName (item) {
            return item.threshold >= this.thresholdName;
        },
        filterExposureName (item) {
            return item.exposure >= this.exposureName;
        },
        filterBalanceName (item) {
            return item.balance <= this.balanceName;
        },
        filterCreditName (item) {
            return item.credit >= this.creditName;
        },
        filterEquityName (item) {
            return item.equity >= this.equityName;
        },
        filterPriceName (item) {
            return item.price >= this.priceName;
        },
        filterProfitName (item) {
            return item.profit >= this.profitName;
        },
        filterVolumeTodayName (item) {
            return item.volumeToday >= this.volumeTodayName;
        },
        filterAmountName (item) {
            return item.amount >= this.amountName;
        },
        filterThredsholdAmountName (item) {
            return item.thredshold_amount >= this.thredsholdAmountName;
        },
        filterTimeRange (item) {
            return !!(Date.parse(item.time) >= this.timeRange[0] &&
                Date.parse(item.time) <= this.timeRange[1]);
        },
        setFocus (item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        onResize (item) {
            console.log(item);
            // this.update = false;
            // // 在组件移除后，重新渲染组件
            // // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
            // this.$nextTick(() => {
            //     this.update = true;
            // });
            // this.$nextTick(() => {
            //     console.log(document.querySelector("#big_lot").offsetWidth);
            // });
        }
    },
    mounted () {
        // refresh table alert data every 5 mins
        const intervalTime = 300000;
        if (this.name != "tick_check") {
            if (this.timer) {
                clearInterval(this.timer);
            } else {
                this.timer = setInterval(() => {
                    console.log("Refresh Now");
                    this.loadAlertData();
                }, intervalTime);
            }
        }
        const userFunction = this.getFunction("alert");

        if (userFunction.includes("alertDialogLoginStatistic")) this.alertDialogLoginStatistic = 1;
        if (userFunction.includes("alertDialogLoginOpenTrades")) this.alertDialogLoginOpenTrades = 1;
        if (userFunction.includes("alertDialogLoginInfo")) this.alertDialogLoginInfo = 1;

        if (this.name == "tick_check") {
            this.dataHeaders = this.alertHeader["tick_check"];
            // this.dataHeaders = [
            //     { text: "Symbol", value: "symbol", align: "left" },
            //     { text: "Now(MT4)", value: "now", align: "left" },
            //     { text: "Last Update Time(MT4)", value: "lastUpdateTime", align: "left" },
            //     { text: "Gap", value: "gap", align: "left" },
            //     { text: "Threshold", value: "threshold", align: "left" }
            // ];
        } else {
            this.dataHeaders = this.alertHeader[this.name];
            if (this.name === "big_lot") {
                console.log("dataHeader: ", this.dataHeaders);
            }
        }

        for (let i = 0; i < this.dataHeaders.length; i++) {
            this.csvFields[this.dataHeaders[i].text] = this.dataHeaders[i].value;
        }
        let nameArr = this.name.split("_");
        nameArr = nameArr.map(function (i) {
            return i.slice(0, 1).toUpperCase() + i.slice(1);
        });
        this.title = nameArr.join(" ");
        if (this.name != "tick_check") {
            this.loadAlertData();
        } else {
            this.loadAlertDataTick();
        }

        if (this.name == "profit_taker") {
            this.sortBy = "profitToday";
        }
        // 获取当前账号所有的alert频道
        const alertChannel = JSON.parse(localStorage.getItem("alertChannel"));

        // loop所有broker每个broker的每个alert进行alert订阅
        for (const item of this.brokerMap) {
            if (this.name == "tick_check") {
                // 订阅tick警报
                const tickChannel = "TickAlert:" + item.broker_id.toString();

                // 订阅前先销毁之前有的
                this.$store.state.socket.removeListener(tickChannel);

                // 订阅tick警报
                this.$store.state.socket.on(tickChannel, (data) => {
                    // tick 警报事件逻辑
                    // console.log("Tick Data: ", data);
                    // console.log(Object.values(this.apiDataSource));
                    const brokerId = data.broker_id;

                    // 是否是当前broker，处理相关表格插入
                    if (brokerId == this.brokerId) {
                        const index = this.apiDataSource.findIndex(
                            (item) => item.symbol == data.symbol
                        );
                        if (index != -1) {
                            // 找到这个产品，直接更新
                            if (data.alert == 0) {
                                // 删除此产品
                                this.apiDataSource.splice(index, 1);
                            } else {
                                this.apiDataSource[index] = data;
                            }
                        } else {
                            if (data.alert == 1) this.apiDataSource.unshift(data);
                        }
                    }
                });
            } else {
                const brokerName = item.broker;

                const selectedChannel = brokerName + "-AlertsChannel:" + this.name;

                // 订阅前先销毁之前有的
                this.$store.state.socket.removeListener(selectedChannel);

                // 订阅警报
                this.$store.state.socket.on(selectedChannel, (data) => {
                    data = JSON.parse(data);
                    // 所有警报事件逻辑
                    // console.log("Hello Alert ==> ", data);
                    if (data[0] === null) return;
                    // 去除custom
                    const newAlertData = data.map((el) => {
                        const custom = el.custom;
                        el = Object.assign(el, custom);
                        delete el.custom;
                        return el;
                    });

                    const alertName = data[0].alert_name;

                    let alertData = newAlertData;

                    // 拼接警报消息处理
                    let msg = "";
                    if (alertData.length != 0) {
                        // 拼接消息逻辑
                        msg =
                            " " +
                            data[0].alert_name.replace("_", " ").toUpperCase() +
                            " - " +
                            data[0].broker.replace("_", " ").toUpperCase() +
                            "\n";
                        let count = 0;
                        for (const item in data[0]) {
                            if (
                                this.$store.state.alertNotificationShowItem[
                                    alertName
                                ][item]
                            ) {
                                let displayValue = data[0][item];

                                if (
                                    !isNaN(data[0][item]) &&
                                    item != "login" &&
                                    item != "order" &&
                                    item != "orderTicket"
                                ) {
                                    displayValue = displayValue.toFixed(2);
                                }

                                msg +=
                                    " " +
                                    item.replace("_", "").toUpperCase() +
                                    ": " +
                                    displayValue;

                                if (count % 2 == 0) {
                                    msg += "\n";
                                }
                                count += 1;
                            }
                        }

                        // 获取当前alert的broker
                        const alertBroker = alertData[0].broker;

                        // 判断alert是否有object
                        let hasBook = true;
                        if (!alertData[0].hasOwnProperty("book")) { hasBook = false; }

                        // 读取localStorage配置
                        const dataSettings = localStorage.getItem(
                            "alertNotificationSettings"
                        );

                        // 检查该alert是否应该显示在信息流里面
                        if (dataSettings != null && dataSettings != undefined) {
                            // console.log("Alert Broker: ", alertBroker);
                            const settings = JSON.parse(dataSettings)
                                .filter((i) => i.broker == alertBroker)
                                .map((i) => i.rule)[0];

                            // console.log("Current Alert Settings: ", settings);
                            // console.log("Check Alert =========");
                            // console.log(data[0].alert_name);
                            const filterChannel = settings
                                .filter((i) => i.console == 1)
                                .map((i) => i.value);
                            // console.log("Filter Channel: ", filterChannel);
                            // console.log(filterChannel);
                            alertData = alertData.filter((i) =>
                                filterChannel.includes(i.alert_name)
                            );
                            // console.log(alertData);
                            // console.log("End Check Alert =========");
                        }

                        // console.log("Filter 1: ", alertData.length);

                        const alertHideSettings =
                            localStorage.getItem("alertHide");
                        if (
                            alertHideSettings != null &&
                            alertHideSettings != undefined &&
                            hasBook
                        ) {
                            const settings = JSON.parse(alertHideSettings);
                            // console.log("Check Book =========");
                            // console.log(data[0].book);
                            // console.log(settings);
                            alertData = alertData.filter(
                                (i) => !settings.includes(i.book)
                            );
                            // console.log(alertData);
                            // console.log("End Check Book =========");
                        }

                        // if (alertName == "big_lot") console.log("BL: ", alertData.length);

                        // 如果alert data还存在，此alert并没有被过滤
                        if (alertData.length != 0) {
                            this.$store.commit("alertCacheSave", alertData);
                            this.$store.commit("alertCount");

                            // 当前表格实时更新部分
                            const newData = data.map((el) => {
                                const custom = el.custom;
                                el = Object.assign(el, custom);
                                delete el.custom;
                                return el;
                            });

                            const brokerId = this.getBrokerIdByName(
                                data[0].broker
                            );

                            // 处理当前表格实时更新事件
                            if (brokerId == this.brokerId) {
                                if (alertData[0].alert_name == "mandate") {
                                    const index = this.apiDataSource.findIndex(
                                        (i) =>
                                            i.type == alertData[0].type &&
                                            i.book == alertData[0].book &&
                                            i.symbol == alertData[0].symbol
                                    );
                                    if (index != -1) {
                                        // 找到这个产品，直接更新
                                        this.apiDataSource[index] = newData[0];
                                    } else {
                                        this.apiDataSource.unshift(newData[0]);
                                    }
                                } else if (
                                    alertData[0].alert_name == "profit_taker"
                                ) {
                                    const index = this.apiDataSource.findIndex(
                                        (i) =>
                                            i.login == alertData[0].login &&
                                            i.broker == alertData[0].broker &&
                                            i.book == alertData[0].book &&
                                            i.symbol == alertData[0].symbol
                                    );

                                    if (index != -1) {
                                        // 找到这个产品，直接更新
                                        this.apiDataSource[index] = newData[0];
                                    } else {
                                        this.apiDataSource.unshift(newData[0]);
                                    }
                                } else if (
                                    alertData[0].alert_name == "large_exposure"
                                ) {
                                    const index = this.apiDataSource.findIndex(
                                        (i) =>
                                            i.login == alertData[0].login &&
                                            i.broker == alertData[0].broker &&
                                            i.book == alertData[0].book &&
                                            i.symbol == alertData[0].symbol
                                    );

                                    if (index != -1) {
                                        // 找到这个产品，直接更新
                                        this.apiDataSource[index] = newData[0];
                                    } else {
                                        this.apiDataSource.unshift(newData[0]);
                                    }
                                } else {
                                    newData.forEach((element) => {
                                        this.apiDataSource.unshift(element);
                                    });
                                }
                            }
                        }
                    }
                });
            }
        }
    },
    destroyed () {
        Object.keys(localStorage).forEach(function (key) {
            if (key.indexOf("alertRemoteHistory:") != -1) {
                localStorage.removeItem(key);
            }
        });
        clearInterval(this.timer);
    }
};
</script>
<style>
tr.v-data-table__selected {
    background: #7d92f5 !important;
}
.custom-alert-table-header > .col > .v-input > .v-input__control > .v-messages {
    display: none;
}
.custom-alert-table-header
    .v-input--selection-controls
    .v-input__slot
    > .v-label,
.v-input--selection-controls .v-radio > .v-label {
    font-size: 14px;
}
.custom-alert-table-header
    .v-input--selection-controls
    .v-input__slot
    > .v-input--selection-controls__input {
    margin-right: 2px;
}
.v-dialog.v-dialog--active .v-toolbar__content:hover {
    cursor: pointer;
}
.v-data-table-header__icon {
    margin-left: -2px;
}
table > thead > tr > th:nth-child(n) > button {
    margin-left: -13px;
    margin-right: -10px;
    width: 10%;
}
#global-container
    > div.home
    > div
    > div
    > div:nth-child(6)
    > div:nth-child(n)
    > div
    > div
    > div.pr-0.pl-0.col-md-12.col-lg-12.col-12
    > div.v-data-table.elevation-0.alert-datatable.v-data-table--dense.v-data-table--fixed-header.v-data-table--has-top.theme--dark
    > div.row.mb-1.custom-alert-table-header
    > div
    > div {
    height: 30px;
}
</style>
