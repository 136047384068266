import moment from "moment";

export const dataDisplay = {
    methods: {

        /**
         * 格式化tooltip
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        dataDisplayApr (data) {
            if (JSON.stringify(data) == "{}") return;
            if (data.length == 0) {
                return "";
            }
            let result = "";
            result +=
                "<p class=\"mb-1\">Book: " + data.book.toString() + "</p>\n";
            result +=
                "<p class=\"mb-1\">Balance: " +
                data.balance.toString() +
                "</p>\n";
            result +=
                "<p class=\"mb-1\">DPM: " + data.dpm.toString() + "</p>\n";
            result +=
                "<p class=\"mb-1\">Equity: " +
                data.equity.toString() +
                "</p>\n";
            result +=
                "<p class=\"mb-1\">Prev Profit: " +
                data.previous_profit.toString() +
                "</p>\n";
            result +=
                "<p class=\"mb-1\">Profit: " +
                data.profit.toString() +
                "</p>\n";
            result +=
                "<p class=\"mb-1\">Volume USD: " +
                data.volume_usd.toString() +
                "</p>\n";
            result +=
                "<p class=\"mb-1\">Net Deposit: " +
                data.net_deposit.toString() +
                "</p>";
            result +=
                "<p class=\"mb-1\">Deposit: " +
                data.deposit.toString() +
                "</p>";
            result +=
                "<p class=\"mb-1\">Withdraw: " +
                data.withdraw.toString() +
                "</p>";
            result +=
                "<p class=\"mb-1\">Swaps: " + data.swaps.toString() + "</p>";

            return result;
        }
    }
};
