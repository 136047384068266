<!-- Alert overview page. Shows all history intraday alerts of all available brokers -->
<template>
    <v-card>
        <v-row><v-col cols="12"></v-col></v-row>
        <!-- broker selection input -->
        <v-row v-if="brokers.length > 1">
            <v-col cols="4">
                <v-menu offset-y v-if="root">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="warning" dark v-bind="attrs" v-on="on">{{ selectedBroker }}</v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in this.brokers" :key="index"
                            @click="changeBroker(item.broker, item.broker_id)">
                            <v-list-item-title>{{ item.broker }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <!-- user layout configuration -->
        <v-row>
            <v-col cols="12">
                <v-card outlined class="elevation-0 pt-2 pl-2 pt-3 pb-3">
                    Layout:
                    <v-btn small color="primary" class="mr-2" v-on:click="changeLayoutStatus(2)" :outlined="checkLayoutStatus(2)">
                        6 Columns
                    </v-btn>
                    <v-btn small color="primary" class="mr-2" v-on:click="changeLayoutStatus(3)" :outlined="checkLayoutStatus(3)">
                        4 Columns
                    </v-btn>
                    <v-btn small color="primary" class="mr-2" v-on:click="changeLayoutStatus(4)" :outlined="checkLayoutStatus(4)">
                        3 Columns
                    </v-btn>
                    <v-btn small color="primary" class="mr-2" v-on:click="changeLayoutStatus(6)" :outlined="checkLayoutStatus(6)">
                        2 Columns
                    </v-btn>
                    <v-btn small color="primary" class="mr-2" v-on:click="changeLayoutStatus(12)" :outlined="checkLayoutStatus(12)">
                        Full Size
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>

        <v-row><v-col cols="12"></v-col></v-row>

        <!-- all alert component rendering  -->
        <v-row>
            <v-col :lg="layoutAlert.cols" :md="layoutAlert.cols" xs="12" cols="12" v-if="item !== 'tick_check'"
                v-for="item in alertChannel.channel" :key="item">
                <DatatableAlert ref="child" v-if="loadTable" :name="item" :dataSource="alertDataSource[item]"
                    :itemsPerPage="-1" :broker="selectedBroker" :brokerId="selectedBrokerId" :id="item"
                    @openGlobalFilter="openGlobalFilter" :highlight="highlight" @checkHeader="checkHeader" />
            </v-col>
        </v-row>

        <!-- broker selection input -->
        <v-row v-if="brokers.length > 1">
            <v-col cols="4">
                <v-menu offset-y v-if="root">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="warning" dark v-bind="attrs" v-on="on">
                            {{ selectedBroker }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in this.brokers" :key="index"
                            @click="changeBroker(item.broker, item.broker_id)">
                            <v-list-item-title>{{ item.broker }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <!-- render tick alert only -->
        <v-row>
            <v-col lg="12" md="12" xs="12" cols="12">
                <DatatableAlert ref="child"
                    v-if="checkShowAlertSettings('tick_check') &&loadTable && frontPermission.includes('tick_check')"
                    :name="'tick_check'" :dataSource="alertDataSource['tick_check']" :itemsPerPage="30"
                    :id="'tick_check'" :broker="selectedBroker" :brokerId="selectedBrokerId"
                    @openConfig="openConfig" />
            </v-col>
        </v-row>

        <!-- tick alert configuration -->
        <v-dialog v-model="dialog.visible" max-width="1000" :click:outside="this.$store.commit('triggerTickConfig', false, '', '')">
            <v-card>
                <v-card-title class="headline">
                    {{ dialog.title }}
                </v-card-title>

                <v-card-actions>
                    <v-row v-if="dialog.name === 'tick_check'">
                        <v-col lg="12" md="12" xs="12" cols="12" v-if="allowJsonEditor === 1">
                            <v-tabs>
                                <v-tab v-on:click="changeConfigTab('normal')">FORM</v-tab>
                                <v-tab v-on:click="changeConfigTab('json')">JSON</v-tab>
                            </v-tabs>
                        </v-col>
                        <v-col lg="12" md="12" xs="12" cols="12" v-if="configMode === 'json'">
                            <JsonEditor ref="jsonEditor" v-model="dialog.config" v-if="dialog.loadJson" />
                        </v-col>
                        <v-col lg="12" md="12" xs="12" cols="12" v-if="configMode === 'normal'">
                            <v-row>
                                <v-col cols="12" lg="12" md="12" xs="12">
                                    <v-btn color="primary"
                                        @click="downloadcsvExcel(csvConfigDownload, `${selectedBroker}_tick_check.csv`, 'text/csv;charset=utf-8')">
                                        <v-icon dense>mdi-microsoft-excel</v-icon>&nbsp;&nbsp;
                                        Download Config
                                    </v-btn>
                                    &nbsp;&nbsp;
                                    <v-btn color="green darken-1" onclick="document.getElementById('getFile').click()" :loading="csvUploading">
                                        <v-icon dense>mdi-file-move</v-icon>&nbsp;&nbsp;
                                        Upload Config
                                        <input 
                                            id="getFile" type="file" 
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            style="display:none"
                                            @click="csvuploadclicked"
                                            @change="readUploadExcel"
                                        />
                                    </v-btn>
                                    <FileUpload :selectedBroker="selectedBroker"/>
                                </v-col>
                            </v-row>
                            <v-row></v-row>

                            <v-text-field class="mt-4 mb-4" v-model="tickCheckTable.search"
                                append-icon="mdi-search" label="Search Symbol..." single-line hide-details>
                            </v-text-field>

                            <v-row class="mt-4" v-if="tickCheckTable.selectedData.length !== 0">
                                <v-col cols="12" lg="3" md="3" xs="12">
                                    <v-autocomplete label="Batch Edit - Active Status" dense
                                        @change="tickChangeActive"
                                        :items="[{text: 'On', value: 1}, {text: 'Off', value: 0}]"
                                        item-text="text" item-value="value" chips small-chips></v-autocomplete>
                                </v-col>

                                <v-col cols="12" lg="3" md="3" xs="12">
                                    <v-text-field class="mt-n2 mb-2" @change="tickChangeTh"
                                        label="Tick Check Threshold" single-line hide-details>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" lg="3" md="3" xs="12">
                                    <v-text-field class="mt-n2 mb-2" @change="tickChangeServer"
                                        label="Tick Server" single-line hide-details>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-data-table dense :search="tickCheckTable.search"
                                :headers="tickCheckTable.headers" :items="tickCheckTable.dataSource"
                                item-key="symbol" class="elevation-1" :single-select="false" show-select
                                v-model="tickCheckTable.selectedData"
                                :items-per-page="tickCheckTable.itemsPerPage">

                                <template v-slot:item.active="{ item }">
                                    <v-switch :input-value="item.active" label="On" dense small
                                        class="mt-0 pt-0" style="height: 20px;"
                                        @change="(event) => tickCheckTableEventActive(event, item)"></v-switch>
                                </template>

                                <!--
                                <template v-slot:item.email_gap="props">
                                    <v-edit-dialog :return-value.sync="props.item.email_gap" :large="true"
                                        @save="tickCheckTableSave" @cancel="tickCheckTableCancel"
                                        @open="tickCheckTableOpen" @close="tickCheckTableClose"
                                        :cancel-text="'Cancel'" :save-text="'Save'">
                                        {{ props.item.email_gap }}
                                        <template v-slot:input>
                                            <v-text-field v-model="props.item.email_gap" type="number"
                                                label="Edit" single-line counter></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                -->

                                <template v-slot:item.th="props">
                                    <v-edit-dialog :large="true"
                                        @save="tickCheckTableSave(props.item)" @cancel="tickCheckTableCancel(props.item.th)"
                                        @open="tickCheckTableOpen(props.item.th)" @close="tickCheckTableClose(props.item.th)"
                                        :cancel-text="'Cancel'" :save-text="'Save'">
                                        {{props.item.th}}
                                        <template v-slot:input>
                                            <v-text-field v-model="itemcopy" type="text" label="Edit" :rules="[chekConstraintsThinput]"
                                                single-line counter></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>

                            </v-data-table>

                            <!-- <JsonEditor ref="jsonEditor" v-model="dialog.config" v-if="dialog.loadJson" /> -->
                        </v-col>
                    </v-row>
                    <v-row v-if="dialog.name !== 'tick_check'">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <!-- <json-form v-if="dialog.loadJson" @updateData="updateJson" :data="dialog.config" :name="dialog.name"></json-form> -->
                            <JsonEditor ref="jsonEditor" v-model="dialog.config" v-if="dialog.loadJson" />
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text
                        @click="dialog.visible = false && this.$store.commit('triggerTickConfig', false, '', '')">Cancel</v-btn>
                    <v-btn color="green darken-1" text @click="saveConfig()" :loading="dialog.loading">
                        <span>Save</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="globalFilter.visible" max-width="350px" scrollable>
            <v-card>
                <v-card-title class="headline">
                    {{ globalFilter.title }}
                </v-card-title>
                <v-card-text>
                    <!-- <v-card-actions> -->
                    <v-row>
                        <!-- {{ globalFilter.rule[globalFilter.alertName] }}
                        {{ globalFilter.alertName }} -->
                        <v-col lg="12" md="12" xs="12" cols="12" v-if="globalFilter.alertName === 'big_lot'">
                            <v-text-field label="Volume >= " v-model.number="globalFilter.rule.big_lot.volume"
                                type="number" hide-details="auto" />
                        </v-col>

                        <v-col lg="6" md="6" xs="12" cols="12" v-if="globalFilter.alertName === 'mandate'">
                            <v-text-field label="Lots >= " v-model.number="globalFilter.rule.mandate.lots"
                                type="number" hide-details="auto" />
                        </v-col>

                        <v-col lg="6" md="6" xs="12" cols="12" v-if="globalFilter.alertName === 'mandate'">
                            <v-text-field label="PNL >= " v-model.number="globalFilter.rule.mandate.pnl"
                                type="number" hide-details="auto" />
                        </v-col>

                        <v-col lg="12" md="12" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'profit_taker'">
                            <v-text-field label="PNL >= "
                                v-model.number="globalFilter.rule.profit_taker.profitToday" type="number"
                                hide-details="auto" />
                        </v-col>

                        <v-col lg="12" md="12" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'locking_position'">
                            <v-text-field label="Locking Position >= "
                                v-model.number="globalFilter.rule.locking_position.lockingPosition"
                                type="number" hide-details="auto" />
                        </v-col>

                        <v-col lg="6" md="6" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'trade_on_credit'">
                            <v-text-field label="Equity >= "
                                v-model.number="globalFilter.rule.trade_on_credit.equity" type="number"
                                hide-details="auto" />
                        </v-col>

                        <v-col lg="6" md="6" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'trade_on_credit'">
                            <v-text-field label="Credit >= "
                                v-model.number="globalFilter.rule.trade_on_credit.credit" type="number"
                                hide-details="auto" />
                        </v-col>

                        <v-col lg="12" md="12" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'large_exposure'">
                            <v-text-field label="Exposure >= "
                                v-model.number="globalFilter.rule.large_exposure.exposure" type="number"
                                hide-details="auto" />
                        </v-col>

                        <!--
                        <v-col lg="12" md="12" xs="6" cols="6" v-if="globalFilter.alertName === 'fast_trade'">
                            <v-text-field label="Volume >= " v-model.number="globalFilter.rule.fast_trade.volume" type="number" hide-details="auto"/>
                        </v-col>
                            -->
                        <v-col lg="12" md="12" xs="6" cols="6" v-if="globalFilter.alertName === 'fast_trade'">
                            <v-text-field label="Volume >= "
                                v-model.number="globalFilter.rule.fast_trade.volume" type="number"
                                hide-details="auto" />
                        </v-col>

                        <v-col lg="12" md="12" xs="12" cols="12" v-if="globalFilter.alertName === 'watch_list'">
                            <!-- 从API拿到服务器的配置数据，添加用户login的select box -->
                            <v-select v-model="watchListValue" :items="watchList" chips label="Login" dense solo
                                loader-height="300px" multiple>
                            </v-select>
                            <!-- <v-text-field label="Login(Seprate by Comma: 111,222,333) "
                                v-model.number="globalFilter.rule.watch_list.logins" type="number"
                                hide-details="auto" />-->
                        </v-col>

                        <v-col lg="12" md="12" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'same_direction'">
                            <v-text-field label="Volume >= "
                                v-model.number="globalFilter.rule.same_direction.totalVolume" type="number"
                                hide-details="auto" />
                        </v-col>

                        <v-col lg="12" md="12" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'volume_alert'">
                            <v-text-field label="Volume >= "
                                v-model.number="globalFilter.rule.volume_alert.volumeToday" type="number"
                                hide-details="auto" />
                        </v-col>
                        <v-col lg="12" md="12" xs="12" cols="12"
                            v-if="globalFilter.alertName === 'withdraw_deposit'">
                            <v-select v-model="wtdDepValue" :items="wtdDep" chips label="Login" dense solo
                                loader-height="300px" multiple>
                            </v-select>
                        </v-col>
                    </v-row>
                    <!-- </v-card-actions> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="globalFilter.visible = false">Cancel</v-btn>
                    <v-btn color="green darken-1" text @click="alertGlobalFilterHandler().save()"
                        :loading="globalFilter.loading">
                        <span>Save</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>
<script>
// import GlobalAlertConfigBigLot from "@components/ComponentGlobalAlertConfigBigLot";
import * as apiAlertConfig from "@components/api/pk/alert-config";
import * as apiAlertConfigTickCheck from "@components/api/pk/alert-config-tick-check";
import DatatableAlert from "./components/ComponentDatatableAlert";
import FileUpload from "@components/FileUpload"
import * as apiSettingsAlertGlobalFilter from "@components/api/pk/settings-global-filter";
import * as apiAlertChannel from "@components/api/pk/alert-channel";
import { mapState } from "vuex";
import pako from "pako";
import { alert } from "@components/mixins/alert";
import { snackbar } from "@components/mixins/snackbar";
import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import JsonEditor from "@components/ComponentJsonEditor";
// import * as easings from "vuetify/lib/services/goto/easing-patterns";
import goTo from "vuetify/lib/services/goto";
import * as apiSettingsUserConfig from "@components/api/pk/settings/user-config";

export default {
    components: {
        DatatableAlert,
        JsonEditor,
        FileUpload
    },
    mixins: [alert, helper, permission, snackbar],
    data () {
        return {
            csvUploading: false,
            csvConfigDownload : "",
            itemcopy: 0, // copy the user's input th value.
            thinputrang: { start: -100, end: 999999 }, // set the range of th input
            layoutAlert: {
                cols: 3,
                alertHeader: {}
            },
            highlight: {
                alertName: "",
                data: null
            },
            panelDefault: [],
            // configMode: "csv",
            configMode: "normal",
            tickCheckTeleChannel: -1,
            tickCheckEmailList: [],
            tickCheckSendEmail: 0,
            boxWidth: 0,
            tickCheckTable: {
                selectedData: [],
                headers: [
                    { text: "Symbol", value: "symbol" },
                    { text: "Active", value: "active" },
                    { text: "Threshold(Sec)", value: "th" },
                    { text: "Server", value: "server" }
                ],
                dataSource: [],
                itemsPerPage: 10,
                search: ""
            },
            globalFilter: {
                alertName: "",
                title: "Global Filter Alert",
                visible: false,
                loading: false,
                config: [],
                loadJson: false,
                selectedAlert: "",
                brokerId: -1,
                rule: {
                    big_lot: { volume: 1 },
                    mandate: { lots: 1, pnl: 1 },
                    profit_taker: { profitToday: 1000 },
                    locking_position: { lockingPosition: 2.5 },
                    trade_on_credit: { equity: 1, credit: 1 },
                    large_exposure: { exposure: 1 },
                    fast_trade: { volume: 1 },
                    watch_list: { logins: "" },
                    tick_check: {},
                    same_direction: { totalVolume: 1 },
                    volume_alert: { volumeToday: 1 }
                }
            },
            panel: [1],
            frontPermission: [],
            disbleTest: false,
            dataBiglot: [],
            listBook: ["B", "GS", "H", "B1706", "Z"],
            selectedBrokerId: -1,
            selectedBroker: "",
            brokers: [],
            alertChannel: [],
            alertChannelExceptTick: [],
            alertData: [],
            // alertHeader: [],
            alertDataSource: [],
            itemsPerPage: 5,
            loadTable: false,
            root: -1,
            dialog: {
                visible: false,
                name: "",
                config: {},
                loadJson: false,
                loading: false,
                broker: ""
            },
            codeOptions: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                line: true,
                foldGutter: true,
                styleSelectedText: true,
                mode: "text/javascript",
                keyMap: "sublime",
                matchBrackets: true,
                showCursorWhenSelecting: true,
                theme: "monokai",
                extraKeys: { Ctrl: "autocomplete" },
                hintOptions: {
                    completeSingle: false
                }
            },
            watchList: [],
            watchListValue: [],
            watchListLogins: "",
            wtdDep: [],
            wtdDepValue: [],
            wtdDepLogins: "",
            showJsonEditor: 0,
            alertCols: 3
        };
    },
    computed: {
        ...mapState([
            "brokerId",
            "snackmsg",
            "snackbar",
            "snackcolor",
            "alertHeader",
            "allowJsonEditor",
            "tickConfig"
        ]),
        getTickConfigName () { return this.$store.state.tickConfig.name; },
        getTickConfigBroker () { return this.$store.state.tickConfig.broker; },
        getTickConfigVisible () { return this.$store.state.tickConfig.visible; },
        getTickConfigSearch () { return this.$store.state.tickConfig.search; }
    },
    watch: {
        /**
         * check the visible value to determine if open tick config or not
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         *
         * @return  {[type]}      [return description]
         */
        getTickConfigVisible (nv, ov) {
            if (nv) {
                this.configMode = "normal";
                this.dialog.visible = true;
                this.dialog.title = this.$store.state.tickConfig.broker + " - Config for " + this.$store.state.tickConfig.name;
                this.dialog.name = this.$store.state.tickConfig.name;
                this.dialog.broker = this.$store.state.tickConfig.broker;
                this.dialog.loadJson = false;

                this.tickCheckTable.search = this.getTickConfigSearch;
                console.log("F ", this.dialog.title);

                this.tickCheckTable.selectedData = [];
                this.tickCheckTable.itemsPerPage = 10;

                this.configHandlerTickCheckHandler().load();
            }
        },
        "dialog.visible" (nv, ov) {
            if (nv === false) {
                this.$store.state.snackbar = false;
            }
        },
        selectedBroker (nv, ov) {
            this.loadTable = false;

            // remove all listener when change broker
            // this.$store.state.socket.removeAllListeners();
            apiAlertConfigTickCheck.csvDownload({"broker_name": this.selectedBroker}).then(res => {
                // console.log(res.data)
                this.csvConfigDownload = res.data
            })
            this.$nextTick(() => {
                this.loadTable = true;
            });
            this.alertGlobalFilterHandler().load();
                
            this.$emit("changeBroker", nv);
        },
        watchListValue (nv, ov) {
            this.watchListLogins = nv.toString();
        }
    },
    methods: {
        dragFile(e) {
            console.log(e.dataTransfer.files);
        },
        csvuploadclicked () {
            // this.csvUploading = true
            // const clickedit = () => {
            //     // var theFile = document.getElementById('getFile');
            //     this.csvUploading = false
            //     // this.csvUploading = false
            // }

            // document.body.onfocus = clickedit 

        },
        downloadcsvExcel (content, filename, contentType) {
            // Create a blob
            var blob = new Blob([content], { type: contentType });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement('a');
            pom.href = url;
            pom.setAttribute('download', filename);
            pom.click();
                
        },
        readUploadExcel (event) {
            this.csvUploading = true;
            let reader = new FileReader();
            const headerchecking = ['symbol','active','send_email','email_gap','th','server'];
            let dataTypechecking = ['active', 'send_email', 'email_gap', 'th', 'server'];
            let chekcingPostion = {}
            let file = event.target.files[0]
            reader.onload = (event) => {
                console.log(event.target.result);
                
                // split the string into array
                let lines = event.target.result.split('\n');

                // remove the '\r' in the string it may occur after modifyed the file via Excel
                for (let i = 0; i < lines.length; i++) {
                    lines[i] = lines[i].replace('\r', '');
                }
                
                //  check data file is empty
                if (lines.length === 1) {
                    this.snackBarDanger('No data detected in uploaded file')
                    return;
                } 

                // get all columns
                let columns = lines[0].split(','); 
                console.log(columns)
                
                // check header
                if (!(this.compareArray(columns, headerchecking))) {
                    this.snackBarDanger(`column name doest not match`)
                    this.csvUploading = false;
                    return;
                } else {
                    // check the position of each column in the header
                    // user may mess up the seqeuence of columns
                    for (let i = 0; i < columns.length; i++) {
                        chekcingPostion[dataTypechecking[i]] = columns.indexOf(dataTypechecking[i]);
                    }
                    
                    console.log(chekcingPostion);
                    console.log(lines, lines.length)

                    for (let i = 1; i < lines.length; i++) {
                        // console.log(lines[i])
                        // skip the last starting new line
                        if (lines[i] === '') {
                            continue;
                        }
                        // covert string to array, if last column is list/int push them into array
                        let tuple = lines[i].split(',');
                        console.log(tuple.length, headerchecking.length)
                        if (tuple.length > headerchecking.length) {
                            tuple.splice(headerchecking.length-1, tuple.length)
                            let markStart = lines[i].indexOf('"');
                            let markEnd = lines[i].lastIndexOf('"');      
                            let server = lines[i].slice(markStart+1, markEnd) 
                            tuple.push(server)
                        }
                        // console.log(tuple)

                        if (tuple.includes('')) {
                            this.snackBarDanger(`Data is empty at line ${i+1}`)
                            this.csvUploading = false;
                            return
                        } else if (parseInt(tuple[chekcingPostion['active']]) !== 0 && parseInt(tuple[chekcingPostion['active']]) !== 1) {
                            this.snackBarDanger(`Active should be 0 or 1 at line ${i+1}`)
                            this.csvUploading = false;                        
                            return 
                        } else if (isNaN(tuple[chekcingPostion['send_email']])) {
                            this.snackBarDanger(`Send_email should be integer at line ${i+1}`)
                            this.csvUploading = false;
                            return
                        }  else if (isNaN(tuple[chekcingPostion['email_gap']])) {
                            this.snackBarDanger(`Email_gap should be integer at line ${i+1}`)
                            this.csvUploading = false;
                            return
                        } else if (isNaN(tuple[chekcingPostion['th']])) {
                            this.snackBarDanger(`Th should be integer at line ${i+1}`)
                            this.csvUploading = false;
                            return
                        } 
                        // check last column data
                        for (let ele of  tuple[chekcingPostion['server']].split(',')) {
                            // console.log(ele)
                            // return
                            if (isNaN(ele)) {
                                this.snackBarDanger(`Server should be integer or integer at line ${i+1}`)
                                this.csvUploading = false;
                                return
                            } 
                        }
                    }
                }

                let formData = new FormData();
                formData.set("file", file);
                formData.set("broker_name", this.selectedBroker);
                // formData.append('file', file);
                // formData.append('broker_name', this.selectedBroker)
                
                // console.log("file ", file);
                // console.log("broker_name ", this.selectedBroker);
                // console.log("formData: ", formData);
                            
                for (let pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }

                apiAlertConfigTickCheck.csvUpload(formData).then(res => {
                    console.log("CSV Upload: ", res);
                    // this.snackBarSuccess('Upload file successfuly');
                }).then( () => {
                    this.snackBarSuccess('Upload file successfuly');
                    this.csvUploading = false;
                }).catch( () => {
                    this.csvUploading = false;
                })

            }
            reader.readAsText(event.target.files[0]);

            event.target.value = '';
        },
        /**
         *
         * check the user input whatever stafity all constraints.
         *
         * can add more constraints use if statement.
         *
         * @param user input th in string
         * @return Array true -> all constraints stafity. false -> failed
         */

        chekConstraintsThinput (value) {
            const valueInt = parseInt(value);

            if (isNaN(valueInt)) {
                return "Threshold must be integer";
            }

            if (typeof (value) === "string" && value.indexOf(" ") > 0) {
                return "Threshold can not have space";
            }

            if (valueInt < this.thinputrang.start) {
                return "Threshold excessed smallest value: " + this.thinputrang.start;
            };

            if (valueInt > this.thinputrang.end) {
                return "Threshold excessed biggest value: " + this.thinputrang.end;
            };

            return true;
        },

        /**
         * 用户自定义配置
         * @return {[type]} [description]
         */
        userConfigHandler () {
            const self = this;

            return {
                load () {
                    const params = { config_name: "layout_alert", broker_id: -1 };
                    apiSettingsUserConfig.fetch(params).then((res) => {
                        const data = res.data;
                        localStorage.setItem("layoutAlert", JSON.stringify(data.value));
                    });
                },
                update () {
                    self.layoutAlert.alertHeader = self.$store.state.alertHeader;

                    const params = {
                        config_name: "layout_alert",
                        broker_id: -1,
                        config_value: self.layoutAlert
                    };

                    apiSettingsUserConfig.update(params).then((res) => {
                        self.snackBarSuccess("Update layout successfully");
                        self.userConfigHandler().load();
                    });
                }
            };
        },
        checkHeader (params) {
            this.$store.commit("toggleAlertHeader", params);
            this.userConfigHandler().update();
        },
        /**
         * 设置layout column数量
         * @param  {[type]} col [description]
         * @return {[type]}     [description]
         */
        changeLayoutStatus (col) {
            this.layoutAlert.cols = col;
            this.userConfigHandler().update();
            this.$nextTick(() => {
                this.$refs.child.onResize(this.boxWidth = document.querySelector("#big_lot").offsetWidth);
                this.boxWidth = document.querySelector("#big_lot").offsetWidth;
            });
        },
        /**
         * 监听子组件方法
         * @param  {[type]} alertName    [description]
         * @param  {[type]} headerObject [description]
         * @return {[type]}              [description]
         */
        changeTableHeader () {
            console.log("Fucker Here");
            // this.userConfigHandler().update();
        },
        /**
         * 检查layout column的数量对button样式进行操作
         * @param  {[type]} col [description]
         * @return {[type]}     [description]
         */
        checkLayoutStatus (col) {
            if (this.layoutAlert.cols === col) return false;
            return true;
        },
        tickChangeActive (data) {
            const symbol = this.tickCheckTable.selectedData.map(
                (item) => item.symbol
            );
            for (let i = 0; i < this.tickCheckTable.dataSource.length; i++) {
                if (symbol.includes(this.tickCheckTable.dataSource[i].symbol)) {
                    this.tickCheckTable.dataSource[i].active = parseInt(data);
                }
            }
        },
        tickChangeSendEmail (data) {
            const symbol = this.tickCheckTable.selectedData.map(
                (item) => item.symbol
            );
            for (let i = 0; i < this.tickCheckTable.dataSource.length; i++) {
                if (symbol.includes(this.tickCheckTable.dataSource[i].symbol)) {
                    this.tickCheckTable.dataSource[i].send_email =
                        parseInt(data);
                }
            }
        },
        // tickChangeGap(data) {
        //     const symbol = this.tickCheckTable.selectedData.map((item) => item.symbol);

        //     for (let i = 0; i < this.tickCheckTable.dataSource.length; i++) {
        //         if (symbol.includes(this.tickCheckTable.dataSource[i].symbol)) {
        //             this.tickCheckTable.dataSource[i].email_gap = parseFloat(data);
        //         }
        //     }
        // },
        /**
         * Change threshold description
         *
         * @param   {[type]}  data  [data description]
         *
         * @return  {[type]}        [return description]
         */
        tickChangeTh(data) {
            const symbol = this.tickCheckTable.selectedData.map((item) => item.symbol);

            for (let i = 0; i < this.tickCheckTable.dataSource.length; i++) {
                if (symbol.includes(this.tickCheckTable.dataSource[i].symbol)) {
                    this.tickCheckTable.dataSource[i].th = parseInt(data);
                }
            }
        },
        tickChangeServer(data) {
            const symbol = this.tickCheckTable.selectedData.map((item) => item.symbol);

            for (let i = 0; i < this.tickCheckTable.dataSource.length; i++) {
                if (symbol.includes(this.tickCheckTable.dataSource[i].symbol)) {
                    this.tickCheckTable.dataSource[i].server = data;
                }
            }
        },
        /**
         * change between json mode and normal mode
         * @param  {[type]} mode [description]
         * @return {[type]}      [description]
         */
        changeConfigTab (mode) {
            this.configMode = mode;
        },

        tickCheckTableSave (data) {
            // interate the data -> find symbol -> set new in
            this.tickCheckTable.dataSource.forEach(element => {
                if (element.symbol === data.symbol) {
                    element.th = this.itemcopy;
                    console.log(element.th);
                }
            });
        },
        tickCheckTableCancel (data) {
            console.log("Action tickCheckTableCancel: ", data);
            // if the user doest not modify data set it to 0
            this.itemcopy = 0;
        },
        tickCheckTableOpen (data) {
            console.log("Action tickCheckTableOpen: ", data);
            // if the data open, then copy th of the item
            this.itemcopy = data;
        },
        tickCheckTableClose (data) {
            console.log("Action tickCheckTableClose: ", data);
            // if the dataclose we do nothing, the data will not be modified
        },

        getSwitchValue (data) {
            if (data === 1) return true;
            return false;
        },
        /**
         * 检测是否ticek check的表格Active有变化
         * @return {[type]} [description]
         */
        tickCheckTableEventActive (event, item) {
            let re = 0;
            if (event) re = 1;
            const symbol = item.symbol;
            const index = this.tickCheckTable.dataSource.findIndex(
                (item) => item.symbol === symbol
            );
            this.tickCheckTable.dataSource[index].active = re;
        },
        /**
         * 检测是否ticek check的表格SendMail有变化
         * @return {[type]} [description]
         */
        tickCheckTableEventSendMail (event, item) {
            let re = 0;
            if (event) re = 1;
            const symbol = item.symbol;
            const index = this.tickCheckTable.dataSource.findIndex(
                (item) => item.symbol === symbol
            );
            this.tickCheckTable.dataSource[index].send_email = re;
        },
        /**
         * 检查当前用户设置是否在边栏显示alert信息
         * @param  {[type]} alertName [description]
         * @return {[type]}           [description]
         */
        checkShowAlertSettings (alertName) {
            const settingsData = localStorage.getItem(
                "alertNotificationSettings"
            );

            if (settingsData !== null && settingsData !== undefined) {
                let settings = JSON.parse(settingsData);
                settings = settings.filter(
                    (item) => item.broker_id === this.selectedBrokerId
                );
                if (settings.length !== 0) {
                    const filterResult = settings[0].rule.filter(
                        (item) => item.value === alertName
                    );
                    if (filterResult.length === 0) return false;

                    if (filterResult[0].overview === 1) return true;

                    return false;
                }
            }

            return true;
        },
        onExpansionChange (alert) {
            this.globalFilter.selectedAlert = alert;
        },
        onTabBrokerChange (brokerId) {
            this.globalFilter.brokerId = brokerId;
            this.alertGlobalFilterHandler().load(brokerId);
        },
        /**
         * 用户自定义alert设置API
         * @return {[type]} [description]
         */
        alertGlobalFilterHandler () {
            const self = this;
            return {
                load () {
                    const params = { broker_id: self.selectedBrokerId };
                    if (self.globalFilter.alertName === "watch_list") {
                        const configParams = {
                            broker: self.selectedBroker,
                            alert: self.globalFilter.alertName
                        };
                        apiAlertConfig.fetch(configParams).then((res) => {
                            self.watchList = res.data.watchList;
                        });
                    }
                    apiSettingsAlertGlobalFilter.fetch(params).then((res) => {
                        // console.log("User Alert Rule: ", res);
                        if (res.data.length !== 0) {
                            self.globalFilter.rule = res.data[0].rule;
                            const key =
                                "brokerRuleAlertFilter:" +
                                self.getBrokerNameById(self.selectedBrokerId);
                            localStorage.setItem(
                                key,
                                JSON.stringify(res.data[0].rule)
                            );
                            if (
                                self.globalFilter.rule.watch_list.logins
                                    .length <= 0
                            ) {
                                self.watchListValue = [];
                            } else {
                                console.log(
                                    self.globalFilter.rule.watch_list.logins
                                );
                                // self.watchListValue =
                                //     self.globalFilter.rule.watch_list.logins.split(
                                //         ","
                                //     );
                                // console.log(self.watchListValue);
                            }
                            // console.log(self.globalFilter.rule.watch_list.logins)
                            // console.log(self.watchListValue)
                        } else {
                            self.globalFilter.rule = JSON.parse(
                                localStorage.getItem("defaultAlertSettings")
                            );
                        }
                    });
                },
                save () {
                    if (self.globalFilter.alertName === "watch_list") {
                        self.globalFilter.rule.watch_list.logins =
                            self.watchListLogins;
                        console.log(self.globalFilter.rule.watch_list.logins);
                    }
                    const params = {
                        brokerId: self.selectedBrokerId,
                        data: self.globalFilter.rule
                    };
                    self.globalFilter.loading = true;
                    self.loadTable = false;

                    apiSettingsAlertGlobalFilter.create(params).then((res) => {
                        self.globalFilter.loading = false;
                        self.$store.state.snackbar = true;
                        self.$store.state.snackmsg = "Update successfully";
                        self.$store.state.snackcolor = "green";

                        const key =
                            "brokerRuleAlertFilter:" +
                            self.getBrokerNameById(self.selectedBrokerId);
                        localStorage.setItem(
                            key,
                            JSON.stringify(self.globalFilter.rule)
                        );
                        self.$nextTick(() => {
                            self.loadTable = true;
                            self.globalFilter.visible = false;
                        });
                    });
                }
            };
        },
        /**
         * 承接子组件的方法调用打开用户自定义alert的配置
         * @param  {[type]} name   [description]
         * @param  {[type]} broker [description]
         * @return {[type]}        [description]
         */
        openGlobalFilter (name, broker) {
            this.globalFilter.visible = true;
            this.globalFilter.alertName = name;
            this.globalFilter.title = this.selectedBroker + " Table Filter";

            this.alertGlobalFilterHandler().load();
        },
        appendAlert (data, name) {
            const passedData = { data: data, name: name };
            this.$refs.child.$emit("appendAlert", passedData);
        },
        /**
         * 打开tick的服务器配置
         * @param  {[type]} name   [description]
         * @param  {[type]} broker [description]
         * @return {[type]}        [description]
         */
        openConfig (name, broker) {
            this.configMode = "normal";
            this.dialog.visible = true;
            this.dialog.title = broker + " - Config for " + name;
            this.dialog.name = name;
            this.dialog.broker = broker;
            this.dialog.loadJson = false;

            this.tickCheckTable.selectedData = [];
            this.tickCheckTable.itemsPerPage = 10;

            this.configHandlerTickCheckHandler().load();
        },
        /**
         * 保存服务器配置
         * @return {[type]} [description]
         */
        saveConfig () {
            if (this.dialog.name === "tick_check") {
                this.configHandlerTickCheckHandler().update();
            } else {
                this.configHandler().save();
            }
        },
        /**
         * Tick警报的单独配置
         * @return {[type]} [description]
         */
        configHandlerTickCheckHandler () {
            const self = this;
            return {
                load () {
                    const params = { broker_id: self.selectedBrokerId, broker_name: self.selectedBroker };
                    self.tickCheckTable.dataSource = [];
                    apiAlertConfigTickCheck.fetch(params).then((res) => {
                        self.tickCheckTable.dataSource = res.data.config;
                        self.dialog.config = res.data;
                        self.dialog.loadJson = true;
                    });
                },
                update () {

                    // define params for post request
                    let params = {
                        broker_id: self.selectedBrokerId,
                        broker_name: self.selectedBroker
                    };

                    if (self.configMode === "normal") {
                        params["config"] = self.tickCheckTable.dataSource;
                    } else {
                        params["config"] = JSON.parse(self.dialog.config)["config"];
                    }

                    apiAlertConfigTickCheck.update(params).then((res) => {
                        self.dialog.loading = false;
                        self.snackBarSuccess("Update tick config successfully!");
                        self.$emit("reloadTickGlobalAlert");
                    });
                }
            };
        },
        configHandler () {
            const self = this;

            return {
                load (broker, name) {
                    const params = { broker: broker, alert: name };
                    self.dialog.loading = false;
                    self.dialog.config = {};
                    apiAlertConfig.fetch(params).then((res) => {
                        self.$nextTick(() => {
                            self.dialog.config = res.data;
                            // console.log(
                            //     "Mother Fucker: ",
                            //     typeof self.dialog.config
                            // );
                            self.dialog.loadJson = true;
                        });
                    });
                },
                save () {
                    let configParam = {};
                    try {
                        if (
                            typeof self.dialog.config === "object" &&
                            self.dialog.config !== null
                        ) {
                            configParam = self.dialog.config;
                        } else {
                            JSON.parse(self.dialog.config);
                            configParam = JSON.parse(self.dialog.config);
                        }
                    } catch (e) {
                        // console.log(e.toString(), " ===> ", self.dialog.config);
                        self.$store.state.snackbar = true;
                        self.$store.state.snackcolor = "red";
                        self.$store.state.snackmsg =
                            self.dialog.name + ": Invalid Json";

                        return;
                    }

                    const params = {
                        broker: self.dialog.broker,
                        alert: self.dialog.name,
                        config: configParam
                    };

                    // console.log(self.dialog.config);

                    self.dialog.loading = true;
                    apiAlertConfig.create(params).then((res) => {
                        setTimeout(() => {
                            self.$store.state.snacktimeout = 2500;
                            self.$store.state.snackbar = true;
                            self.$store.state.snackcolor = "success";
                            self.$store.state.snackmsg =
                                "Config " +
                                self.dialog.name +
                                " Update Successfully";
                        }, 1450);
                        setTimeout(() => {
                            self.loading = false;
                            self.dialog.visible = false;
                        }, 2000);
                    });
                }
            };
        },
        loadAlertChannel () {
            const self = this;
            return apiAlertChannel.fetch().then((res) => {
                // self.alertChannel = res.data.channel;
                self.alertChannel = res.data;
                // console.log(
                //     "🚀 ~ file: Overview.vue ~ line 508 ~ returnapiAlertChannel.fetch ~ self.alertChannel",
                //     self.alertChannel.channel
                // );

                // const keys = self.alertChannel.channel;

                // self.alertChannelExceptTick = keys.filter(function(item) {return item !== 'tick_check' && self.frontPermission.includes(item)})
                // console.log('show alertMain', self.alertChannelExceptTick)
                // console.log('front', self.frontPermission)
            });
        },
        unzip (data) {
            // Decode base64 (convert ascii to binary)
            const strData = atob(data);

            // Convert binary string to character-number array
            const charData = strData.split("").map(function (x) {
                return x.charCodeAt(0);
            });

            // Turn number array into byte-array
            const binData = new Uint8Array(charData);

            // Pako magic
            const newData = pako.inflate(binData);

            // Convert gunzipped byteArray back to ascii string:
            const result = String.fromCharCode.apply(
                null,
                new Uint16Array(newData)
            );

            return result;
        },
        establishWs () {},
        changeBroker (broker, brokerId) {
            this.selectedBroker = broker;
            this.selectedBrokerId = brokerId;
        },
        updateJson (data) {
            this.dialog.config = data;
        }
    },
    mounted () {
        this.establishWs();
    },
    async created () {
        // let userFunction = this.getFunction("alert");

        // if (userFunction.includes("jsonEditor")) {
        //     this.showJsonEditor = 1;
        // }

        await this.loadAlertChannel();

        // 读取当前页面layout配置信息
        let layoutAlert = localStorage.getItem("layoutAlert");
        if (layoutAlert !== null && layoutAlert !== undefined) {
            layoutAlert = JSON.parse(layoutAlert);
            this.layoutAlert.cols = layoutAlert.cols;
            this.$store.commit("assignAlertHeader", layoutAlert.alertHeader);
        }

        const data = JSON.parse(localStorage.getItem("bp"));
        this.frontPermission = data.frontPermission.alert.channel;
        // console.log(
        //     "🚀 ~ file: Overview.vue ~ line 548 ~ created ~ this.frontPermission",
        //     this.frontPermission
        // );
        const brokerArr = data.broker;
        if (brokerArr.length > 1) {
            this.brokers = brokerArr;
            // console.log(
            //     "🚀 ~ file: Home.vue ~ line 592 ~ created ~ this.brokers",
            //     this.brokers
            // );
            this.selectedBrokerId = 1;
            this.selectedBroker = this.brokers[0].broker;
            this.root = 1;
        } else {
            this.brokers = brokerArr;
        }

        // 开始进行页面的滚动跳转
        const alertPageForceDetail = localStorage.getItem("alertPageForceDetail");
        if (alertPageForceDetail !== undefined && alertPageForceDetail !== null) {
            const localStorageAlertPageForceDetail =
                JSON.parse(alertPageForceDetail);

            this.highlight.alertName =
                localStorageAlertPageForceDetail.alert_name;
            this.highlight.data = localStorageAlertPageForceDetail;

            setTimeout(() => {
                const scrollTo =
                    "#" + localStorageAlertPageForceDetail.alert_name;
                goTo(scrollTo);
                localStorage.removeItem("alertPageForceDetail");
                this.$store.commit("toggleGlobalAlertStreamExpansion", null);
            }, 500);
        }

        const localStorageBrokerId = localStorage.getItem(
            "alertPageForceBrokerId"
        );

        if (localStorageBrokerId !== undefined && localStorageBrokerId !== null) {
            this.globalFilter.brokerId = localStorageBrokerId;
            this.selectedBrokerId = localStorageBrokerId;
            this.selectedBroker = this.getBrokerNameById(localStorageBrokerId);
        } else {
            const brokerId = data.broker[0].broker_id;
            this.globalFilter.brokerId = brokerId;
            const broker = data.broker[0].broker;
            this.selectedBrokerId = brokerId;
            this.selectedBroker = broker;
        }

        localStorage.setItem(
            "defaultAlertSettings",
            JSON.stringify(this.globalFilter.rule)
        );
        this.alertGlobalFilterHandler().load();

        // this.loadBookGroup();
        // this.$watch("selectedBroker");

        for (const item in this.alertChannel.channel) {
            this.panelDefault.push(parseInt(item));
        }
    },
    destroyed () {
        const pattern = ["brokerRuleAlertFilter:", "alertCache:"];

        Object.keys(localStorage).forEach(function (key) {
            for (let i = 0; i < pattern.length; i++) {
                if (key === "alertCache:all") continue;
                if (key.indexOf(pattern[i]) !== -1) {
                    localStorage.removeItem(key);
                }
            }
        });
        localStorage.removeItem("alertPageForceBrokerId");
    }
};
</script>
<style>
.custom-expansion
    > .v-expansion-panel--active
    > .v-expansion-panel-header--active {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 46px;
    min-height: 0px;
}
.custom-expansion > .v-expansion-panel > .v-expansion-panel-header {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 46px;
    min-height: 0px;
}
</style>
