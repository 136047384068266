import request from "@utils/pk/request";

export function fetch(query) {
    return request({
        url: "alert/config-tick-check-csv",
        method: "get",
        params: query
    });
}

export function update (query) {
    return request({
        url: "alert/config-tick-check-csv",
        method: "put",
        data: query
    });
}

export function csvDownload(query) {
    return request ({
        url: "alert/config-tick-csv",
        method: "get",
        params: query
    })
}


export function csvUpload (query) {
    return request ({
        url: "alert/config-tick-csv",
        method: "put",
        data: query,
        headers: { "Content-Type": "multipart/form-data" }
    })
}